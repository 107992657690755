import React, { MutableRefObject, useCallback, useEffect, useMemo } from 'react'

import { PlainTableMemo } from './PlainTableMemo'
import { useZeroApiContext } from '../../context'
import {
  useCreateBulkDeleteMutations,
  useCreateBulkEditMutations,
  useEnhanceItemValues,
  useEntityGql,
  useProcessFieldsAsColumns
} from '../../gql-fields'
import { PlainTableProps } from '../../table'
import { useCurrentLocale } from '../../translations'
import { FormQueryProps, GqlMutation, GqlQuery } from '../../types'

export interface GqlTableProps extends FormQueryProps, Omit<PlainTableProps<any>, 'items' | 'columns'> {
  treeLookup?: (row: any, parent: any) => boolean
  rowData?: any
  refItems?: MutableRefObject<any[]>
  remapItems?: (items: any[]) => any[]
  queryRef?: MutableRefObject<GqlQuery<any> | GqlMutation<any> | undefined>
}

export const GqlTable: React.FC<GqlTableProps> = (props) => {
  const context = useZeroApiContext()

  const parentRowData = props.rowData

  const getParentValue = useCallback(
    (value: string | ((rowData: any) => any) | undefined) => {
      if (parentRowData === undefined || value === undefined) return undefined
      if (value instanceof Function) return value(parentRowData)
      return parentRowData[value]
    },
    [parentRowData]
  )

  const { fieldsMap, query, mutation } = useEntityGql({
    ...props,
    list: true,
    all: props.all,
    readOnly: props.readOnly || props.aggregated,
    entityRelFieldName: props.entityRelFieldName || `${context.name.toLocaleLowerCase()}Id`,
    entityRelFieldValue: props.entityRelFieldValue || context.id,
    getParentValue
  })

  if (props.queryRef) {
    props.queryRef.current = query
  }

  const { columns, columnGetValues, detailPanel, aggregatorColumns, aggregateColumns, disaggregateColumns, treeItemsLookup } =
    useProcessFieldsAsColumns(props, fieldsMap, query.refresh)

  const enhanceItemValues = useEnhanceItemValues(props, fieldsMap, columnGetValues)

  const createBulkEditMutations = useCreateBulkEditMutations(props, disaggregateColumns, enhanceItemValues)

  const createBulkDeleteMutations = useCreateBulkDeleteMutations(props, disaggregateColumns)

  const bulkEdit = useCallback(
    async (item: any, _autoRefresh?: boolean) => {
      if (!props.aggregated || !mutation) return undefined

      const mutations = createBulkEditMutations(item)

      if (!mutations) return undefined

      return mutation.bulkEdit(mutations).then(() => {
        if (props.refresh) props.refresh(item)
      })
    },
    [createBulkEditMutations, mutation, props]
  )

  const bulkDelete = useCallback(
    async (item: any, _autoRefresh?: boolean) => {
      if (!props.aggregated || !mutation) return undefined

      const mutations = createBulkDeleteMutations(item)
      if (!mutations) return

      return mutation?.bulkEdit(mutations).then(() => {
        if (props.refresh) props.refresh(item)
      })
    },
    [createBulkDeleteMutations, mutation, props]
  )

  const deleteItem = useMemo(() => {
    if (!mutation) return undefined
    return props.aggregated ? (e) => bulkDelete(e).catch(console.error) : mutation?.deleteItem
  }, [bulkDelete, mutation, props.aggregated])

  const remapItems = props.remapItems

  const items = useMemo(() => {
    let result = (query.items as any[]) || []

    if (aggregatorColumns.length > 0 && result.length > 0) {
      const columnValueGetters = columnGetValues.toMapBy(
        (it) => it.fieldPathName,
        (it) => it.getValue
      )

      const agrItems = result.groupBy((item) => {
        return aggregatorColumns.joinOf('|', (column) => columnValueGetters[column](item))
      })

      result = Object.values(agrItems).map((items) => {
        const agrItem = { ...items[0], _agrItems: items }
        aggregateColumns.forEach(([column, aggregator]) => {
          agrItem[column] = aggregator(items)
        })
        return agrItem
      })
    }

    if (remapItems) {
      return remapItems(result)
    }

    return result
  }, [query.items, aggregatorColumns, remapItems, columnGetValues, aggregateColumns])

  if (props.refItems) props.refItems.current = items

  useEffect(() => {
    if (props.onItems) props.onItems(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onItems ? JSON.stringify(items) : items.length])

  const searchQuery = props.search ?? new URL(window.location.href).searchParams.get('q') ?? undefined

  const saveOrUpdateItem = useMemo(() => {
    if (!mutation) return undefined
    return (data: any) => {
      if (data.__originalRowData) {
        if (props.aggregated) {
          return bulkEdit(data).catch(console.error)
        } else {
          return mutation.updateItem(data.__originalRowData, data)
        }
      }
      if (props.aggregated) {
        return bulkEdit(data).catch(console.error)
      } else {
        return mutation.saveItem(data)
      }
    }
  }, [bulkEdit, mutation, props.aggregated])

  const lang = useCurrentLocale()

  return (
    <PlainTableMemo
      {...props}
      lang={lang}
      search={searchQuery}
      style={props.style}
      onItemClick={props.onItemClick}
      canDelete={props.canDelete}
      onRowAdd={saveOrUpdateItem}
      onRowUpdate={saveOrUpdateItem}
      onRowDelete={deleteItem}
      title={props.title || props.entity}
      isLoading={query.isLoading}
      columns={columns}
      items={items}
      treeItemsLookup={treeItemsLookup}
      detailPanel={detailPanel}
      options={{ ...props.options, tableLayout: 'auto' }}
    />
  )
}
