import React, { CSSProperties, useMemo } from 'react'

import { CellStyle, DetailPanel, EditComponentProps } from '@material-table/core'

import { FieldValueGetter } from './types'
import { DenseTypes, FontSizes } from '../constants'
import { FilterField, GqlDetailPanel, GqlDetailPanelProps, HiddenField, NestedItemForm } from '../fields'
import { GqlTableProps } from '../gql-table'
import { GqlTableColumn } from '../table'
import { useTranslation } from '../translations'
import { FieldMap, GqlComponent, GqlTabletField } from '../types'
import { callOrGet, ifNull } from '../utils'

export const useProcessFieldsAsColumns = (props: GqlTableProps, fieldsMap: FieldMap, refresh?: VoidFunction) => {
  const translate = useTranslation()

  return useMemo(() => {
    const parentRowData = props.rowData
    const getParentValue = (value: string | ((rowData: any) => any) | undefined) => {
      if (parentRowData === undefined || value === undefined) return undefined
      if (value instanceof Function) return value(parentRowData)
      return parentRowData[value]
    }

    const paddings = props.dense ? DenseTypes[props.dense] ?? [6, 16, 6, 16] : [6, 16, 6, 16]
    const fontSize = props.fontSize ? FontSizes[props.fontSize] ?? 12 : 12

    const columns: GqlTableColumn<any>[] = []

    const propsTreeLookup = props.treeLookup
    let treeItemsLookup: ((row: any, rows: any[]) => any) | undefined = propsTreeLookup
      ? (row: any, rows: any[]) => row && rows && rows.find((a) => a && propsTreeLookup(a, row))
      : undefined

    const columnGetValues: FieldValueGetter[] = []

    const aggregateColumns: [string, (items: any[]) => any][] = []
    const aggregatorColumns: string[] = []
    const disaggregateColumns: Record<string, (item: any) => any[]> = {}

    let groupingFields = 0

    const detailPanel: DetailPanel<any>[] = []

    const generateColumns =
      (fieldsMapParent: FieldMap, path: string[], parentEntity?: string) =>
      (child: React.ReactElement<GqlTabletField<any> | GqlDetailPanelProps>) => {
        if (!React.isValidElement(child)) {
          console.warn('Invalid child', child)
          return
        }
        const component = child.type as GqlComponent<any>

        if (component === GqlDetailPanel) {
          detailPanel.push({
            render: ({ rowData }) => React.cloneElement(child, { ...child.props, rowData, refresh } as GqlDetailPanelProps)
          })
          return
        }

        const childProps = child.props as GqlTabletField<any>
        const fieldName = childProps.name

        if (fieldName === undefined) return

        if (props.aggregated && childProps.ignoreOnAggregate) return
        if (!props.aggregated && childProps.ignoreOnNonAggregate) return

        const field = fieldsMapParent[fieldName]

        if (field === undefined && !component.aggregator) return

        if (component === NestedItemForm) {
          if (!field) {
            console.warn("NestedItemForm got null 'field'")
            return
          }
          if (!childProps.children) {
            console.warn('NestedItemForm with no children')
            return
          }
          if (!field.childs) {
            console.warn('field.childs unexpetidely is undefined')
            return
          }
          const columnGenerator = generateColumns(field.childs as FieldMap, path.concat([fieldName]), childProps.gql ?? 'String')
          React.Children.forEach(childProps.children, columnGenerator)
          return
        }

        // if (error) hasError = true

        const getValue = (row: any, valuePath?: string[]) => {
          if (childProps.valueFromParent !== undefined) return getParentValue(childProps.valueFromParent)
          if (valuePath?.length) {
            let value = row
            for (let i = 0; i < valuePath.length; i++) {
              value = value[valuePath[i]] ?? {}
            }
            return value
          }
          if (path.length === 0) return row[fieldName] ?? (field ? field.value : row)
          let value = row
          for (let i = 0; i < path.length; i++) {
            value = value[path[i]] || {}
          }
          return ifNull(value[fieldName], field ? field.value : row)
        }

        const validate =
          childProps.validate || component.validate
            ? (rowData: any) => {
                if (childProps.validate) {
                  return childProps.validate(getValue(rowData), rowData)
                }
                if (component.validate) {
                  return component.validate(childProps, getValue(rowData), rowData)
                }
                return false
              }
            : undefined

        const hasError = (value: any) =>
          (childProps.nullable !== true && !component.readOnly && (value === undefined || value?.length === 0)) ||
          callOrGet(component.validate, childProps, value)

        const render = component.render
        const fieldPathName = path.length > 0 ? path.concat(childProps.name).join('.') : childProps.name

        columnGetValues.push({
          fieldPathName,
          getValue,
          default: childProps.default,
          isValueFromParent: childProps.valueFromParent !== undefined
        })

        if (childProps.treeLookup) {
          const otherField = columnGetValues.find((it) => it.fieldPathName === childProps.treeLookup)
          if (otherField) {
            const otherGetValue = otherField.getValue
            treeItemsLookup = (row, rows) => {
              if (!row || !rows) return undefined
              const rowValue = getValue(row)
              return rows.find((a) => otherGetValue(a) === rowValue)
            }
          } else {
            console.error(`Can not find field ${childProps.treeLookup} in ${columnGetValues.map((it) => it.fieldPathName)}`)
          }
        }

        const aggregate = props.aggregated ? childProps.aggregate ?? (component.aggregate as undefined | ((data: any[]) => any)) : undefined

        if (props.aggregated && !(component === HiddenField || component === FilterField)) {
          if (aggregate) {
            aggregateColumns.push([fieldPathName, aggregate])
          } else {
            aggregatorColumns.push(fieldPathName)
          }
        }

        if (childProps.disaggregate) {
          disaggregateColumns[fieldPathName] = childProps.disaggregate
        } else if (childProps.disaggregateCreateNull) {
          disaggregateColumns[fieldPathName] = (item) => {
            const countRefValue = childProps.disaggregateCreateNull
            if (!countRefValue) return []
            const count = parseInt(typeof countRefValue === 'string' ? item[countRefValue] : countRefValue)
            const values = (item._agrItems ?? []).map((it: any) => it[fieldName])
            if (values.length === count) return values
            if (values.length > count) return values.slice(0, count)
            return values.concat(Array(count - values.length).fill(null))
          }
        }

        if (component === HiddenField || component === FilterField) return

        const renderCol = (row: any, type: 'group' | 'row', valuePath?: string[]) => {
          if (type === 'group') {
            if (childProps.renderGroup) {
              return childProps.renderGroup(row)
            }
            return render?.(childProps, row, { group: row }, type)
          }

          return render?.(
            { fontSize, paddings, size: props.dense, ...childProps, parentEntity, parentRowData },
            getValue(row, valuePath),
            row,
            type
          )
        }

        const renderEdit = (editProps: EditComponentProps<any>) =>
          React.cloneElement(child, {
            ...childProps,
            ...editProps,
            key: fieldPathName,
            size: props.dense,
            paddings,
            fontSize,
            error: hasError,
            parentEntity,
            parentRowData,
            hidden: callOrGet(childProps.hidden, editProps.rowData)
          })

        const columnTitle = React.isValidElement(childProps.label)
          ? childProps.label
          : translate(childProps.label?.toString() || childProps.name.snakeCase().replace(/_id$/g, ''))

        const cellStyle: CellStyle<any> = {
          paddingLeft: paddings[0],
          paddingTop: paddings[1],
          paddingRight: paddings[2],
          paddingBottom: paddings[3],
          ...component.cellStyle
        }

        const headerStyle: CSSProperties = {
          lineHeight: 1.2,
          position: 'sticky',
          whiteSpace: 'nowrap',
          paddingLeft: paddings[0],
          paddingTop: Math.min(paddings[1], 8),
          paddingRight: paddings[2],
          paddingBottom: Math.min(paddings[3], 8),
          fontSize
        }

        const propsCellStyle = childProps.cellStyle

        columns.push({
          fieldName,
          groupName: childProps.group,
          title: columnTitle,
          field: fieldPathName,
          align: childProps.align ?? component.align,
          hidden: component === HiddenField || component === FilterField || callOrGet(childProps.hidden),
          editable: childProps.derived ? 'never' : 'always',
          summary: childProps.summary,
          defaultGroupOrder: childProps.grouping ? groupingFields++ : undefined,
          initialEditValue: callOrGet(childProps.value || childProps.default),
          width: childProps.width !== undefined ? childProps.width : component.shrinkWidth ? 1 : undefined,
          render: render || childProps.renderGroup ? (row, valuePath?) => renderCol(row, 'row', valuePath) : undefined,
          groupRender: render || childProps.renderGroup ? (row) => renderCol(row, 'group') : undefined,
          editComponent: renderEdit,
          validate,
          cellStyle: typeof propsCellStyle === 'function' ? (d, r, c) => ({ ...cellStyle, ...propsCellStyle(d, r, c) }) : cellStyle,
          headerStyle,
          searchable: component.noSearch || (field ?? childProps)?.noSearch,
          customFilterAndSearch: (filterValue, row) => {
            const value = getValue(row)

            const filter = component.filter
            if (filter) {
              return filter(childProps, filterValue, value, row)
            }
            return value && value.toString().cyrillicToLatin().indexOf(filterValue.cyrillicToLatin()) > -1
          }
        })
      }

    React.Children.forEach(props.children, generateColumns(fieldsMap, [], props.entity))

    return {
      columns,
      disaggregateColumns,
      columnGetValues,
      detailPanel,
      aggregatorColumns,
      aggregateColumns,
      treeItemsLookup
    }
  }, [
    fieldsMap,
    props.aggregated,
    props.children,
    props.dense,
    props.entity,
    props.fontSize,
    props.rowData,
    props.treeLookup,
    refresh,
    translate
  ])
}
