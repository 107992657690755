import React, { useEffect } from 'react'

import { FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useTranslation } from '../translations'
import { GqlComponent, GqlTabletField } from '../types'
import { callOrGet, ifNull, preventPropagation } from '../utils'

const useStyles = makeStyles(() => ({
  inlineControl: {
    display: 'inline-grid',
    minWidth: 120
  },
  formControl: {
    display: 'inline-grid',
    flexGrow: 1,
    minWidth: 60
  }
}))

export interface Option {
  value: string
  name: string
  desc?: string
}

interface FormFieldProps extends GqlTabletField<string> {
  options: { [key: string]: string } | Option[] | ((row: any, parentRow: any) => Option[] | { [key: string]: string })
  onClear?: () => void
}

interface SelectOptionProps extends FormFieldProps {
  options: Option[]
  item: any
}

const OptionItem: React.FC<Option> = ({ value, desc, name }) => (
  <MenuItem key={value} value={value} onKeyDown={preventPropagation}>
    {desc ? <ListItemText secondary={desc}>{name}</ListItemText> : name}
  </MenuItem>
)

export const SelectOptionView: GqlComponent<SelectOptionProps, string> = (props) => {
  const { value, item, options, onChange, label } = props

  return (
    <Select
      fullWidth={props.fullWidth}
      style={{ fontSize: props.fontSize }}
      size={props.size !== 'dense' ? 'medium' : 'small'}
      value={value}
      variant={props.isForm || props.isInline ? 'outlined' : 'standard'}
      onKeyDown={preventPropagation}
      inputProps={{ id: `select-${name}` }}
      label={label}
      error={callOrGet(props.error, value)}
      disabled={callOrGet(props.disabled, value, item)}
      onChange={(event: SelectChangeEvent<any>) => {
        const option = options.find((opts) => opts.value === event.target.value)
        onChange?.(option?.value ? option?.value : null)
      }}
    >
      {options.map(OptionItem)}
    </Select>
  )
}

export const SelectOptionForm: GqlComponent<SelectOptionProps, string> = (props) => {
  const translate = useTranslation()
  const classes = useStyles()

  const { name, label } = props

  const labelElement = (
    <span style={{ fontSize: props.fontSize * 1.4 }}>
      {React.isValidElement(label) ? label : translate(label?.toString() || name.snakeCase())}
    </span>
  )
  const labeBackgroundlElement = (
    <span style={{ fontSize: props.fontSize * 1.1 }}>
      {React.isValidElement(label) ? label : translate(label?.toString() || name.snakeCase())}
    </span>
  )

  return (
    <FormControl
      fullWidth={props.fullWidth}
      className={props.isForm ? classes.formControl : classes.inlineControl}
      sx={{ m: props.isForm ? 1 : 0 }}
      size={props.size !== 'normal' ? 'small' : 'medium'}
    >
      <InputLabel htmlFor={`select-${name}`} variant="outlined">
        {labelElement}
      </InputLabel>
      <SelectOptionView {...props} label={labeBackgroundlElement} />
    </FormControl>
  )
}

export const SelectOption: GqlComponent<FormFieldProps, string> = (props) => {
  const item = props.rowData || props.item
  const propsOptions = callOrGet(props.options, item, props.parentRowData)

  const options: Option[] = Array.isArray(propsOptions)
    ? (propsOptions as Option[])
    : Object.keys(propsOptions).map((key) => ({
        value: key,
        name: propsOptions[key]
      }))

  const propsValue = callOrGet(props.value, item)
  const value = propsValue ?? callOrGet(props.default, item) ?? (options.length > 0 && options[0].value)?.toString()

  useEffect(() => {
    if (value !== propsValue?.toString()) {
      props.onChange?.(value)
    }
    // eslint-disable-next-line
  }, [value, propsValue])

  return props.isForm || props.isInline ? (
    <SelectOptionForm {...props} value={value} item={item} options={options} />
  ) : (
    <SelectOptionView {...props} value={value} item={item} options={options} />
  )
}

SelectOption.gql = 'String'

SelectOption.render = (props, rowValue, row) => {
  const propsValue = props.name && rowValue && typeof rowValue === 'object' ? rowValue[props.name] : rowValue
  const value = ifNull(propsValue, callOrGet(props.default, props.item))?.toString()

  const propsOptions = callOrGet(props.options, row, props.parentRowData)

  const options: Option[] = []
  for (const k in propsOptions) {
    options.push({ value: k, name: propsOptions[k] })
  }

  const option = options.find((opts) => opts.value === value)

  if (option) {
    return <div style={{ display: 'inline-block' }}>{option?.name ?? value}</div>
  } else {
    return <div style={{ display: 'inline-block', opacity: 0.8 }}>{value}</div>
  }
}
