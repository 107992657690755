import React, { HTMLInputTypeAttribute, useState } from 'react'

import { InputAdornment, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useTranslation } from '../translations'
import { GqlComponent, GqlTabletField } from '../types'
import { callOrGet, ifNull, parseDecimal, parseInteger } from '../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-grid',
    margin: theme.spacing(1),
    minWidth: '12ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      width: '-webkit-fill-available'
    }
  }
}))

// const StyledTextField = styled(TextField)(({ theme }) => ({
//   display: 'inline-grid',
//   margin: theme.spacing(1),
//   minWidth: '12ch',
//   flexGrow: 1,
//   '& > .MuiTextField-root': {
//     width: '-webkit-fill-available',
//   },
// }))

interface FormFieldProps extends GqlTabletField<any> {
  hint?: string
  type?: HTMLInputTypeAttribute
  intNumber?: boolean
  decimalNumber?: boolean
  standard?: boolean
  endAdr?: string | JSX.Element
}

export const EditText: GqlComponent<FormFieldProps, string> = (props: FormFieldProps) => {
  const classes = useStyles()
  // const theme = useTheme()

  const translate = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [tempValue, setTempValue] = useState<string | null>(null)

  const item = props.item || props.rowData

  let value = callOrGet(props.value, item)

  if (value == null || value === false || (value?.length === 0 && !isOpen)) {
    const defaultVal = callOrGet(props.default, item)
    if (defaultVal != null) {
      value = defaultVal
    }
  }

  if (value === undefined) {
    if (props.valueFromParent && props.parentRowData) {
      value = callOrGet(props.valueFromParent, props.parentRowData)
      const item = props.rowData ?? props.item
      if (item) {
        item[props.name] = value
      }
    }
  }

  const inputStyles: any = { style: { fontSize: props.fontSize } }
  if (!props.isForm) {
    inputStyles.style.padding = 4
  }
  if (props.align) {
    inputStyles.style = { textAlign: props.align }
  }

  const styles: React.CSSProperties = { ...props.style, fontSize: props.fontSize }

  if (!props.isForm || props.inTable) {
    styles.margin = 0
  }

  return (
    <TextField
      className={classes.root}
      // theme={theme}
      fullWidth={props.fullWidth}
      style={styles}
      type={props.type ?? (props.intNumber ? 'number' : 'text')}
      helperText={props.helperText}
      label={props.isForm ? props.label || translate(props.name) : undefined}
      variant={props.isForm && !props.standard ? 'outlined' : 'standard'}
      size={props.size !== 'dense' ? 'medium' : 'small'}
      error={callOrGet(props.error, value)}
      aria-errormessage="Invalid data"
      disabled={callOrGet(props.disabled ?? props.readOnly, value, item)}
      onSubmit={props.onSubmit}
      inputProps={inputStyles}
      InputProps={{
        endAdornment: props.endAdr ? <InputAdornment position="end">{props.endAdr}</InputAdornment> : undefined
      }}
      onChange={(e) => {
        const valueStr = e?.target?.value
        if (!valueStr || valueStr.length === 0) return props?.onChange?.(valueStr !== undefined ? valueStr : null)
        if (props.intNumber) return props?.onChange?.(parseInteger(valueStr))
        if (props.decimalNumber) {
          if (valueStr.endsWith('.') || valueStr.endsWith(',')) {
            setTempValue(valueStr)
            return false
          }
          if (tempValue) setTempValue(null)

          return props?.onChange?.(parseDecimal(valueStr.replace(',', '.')))
        }
        return props?.onChange?.(valueStr?.toString())
      }}
      value={tempValue ?? value ?? ''}
      required={props.nullable !== true}
      sx={{
        '& label': {
          fontSize: props.fontSize ? props.fontSize * 1.2 : undefined,
          '&.Mui-focused': {
            fontSize: props.fontSize ? props.fontSize * 1.5 : undefined
          }
        },
        '& fieldset': {
          fontSize: props.fontSize ? props.fontSize * 1.5 : undefined
        }
      }}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
    />
  )
}
EditText.gql = 'String'
EditText.align = 'left'

EditText.cellStyle = {}

EditText.renderComposite = (_props, value, _row) => value
EditText.renderGroup = (_props, value, _row) => value
