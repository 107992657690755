import { useEffect, useRef } from 'react'

export default () => {
  const hasRun = useRef(false)
  const effectCallback = useRef<(() => void) | undefined>()

  useEffect(() => {
    effectCallback.current?.()
    effectCallback.current = undefined
    hasRun.current = true
  })

  return (callback: () => void) => {
    if (hasRun) {
      callback()
    } else {
      effectCallback.current = callback
    }
  }
}
