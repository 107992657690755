import { useCallback, useEffect, useMemo } from 'react'

import { useResetableState, useSimeReactiveState } from '../../../utils'
import { ReportGroupOptions } from '../types'

export interface GroupOptionsSelection extends Record<string, boolean> {}

export const useReportGroupOptions = (remoteState?: ReportGroupOptions[]) => {
  const [state, setState] = useSimeReactiveState<GroupOptionsSelection>()

  const updateState = useCallback(
    (state: GroupOptionsSelection, reRender = true) => {
      setState((prevState) => {
        if (prevState == null) {
          return state
        } else {
          return { ...prevState, ...state }
        }
      }, reRender)
    },
    [setState]
  )

  const [initialReportFilters] = useResetableState(remoteState, [state != null || remoteState != null], true)

  const remoteSelection = useMemo<GroupOptionsSelection | undefined>(
    () =>
      remoteState?.toMapBy(
        (it) => it.name,
        (it) => it.selected
      ),
    [remoteState]
  )

  useEffect(() => {
    if (remoteSelection == null) return
    if (state == null) {
      updateState(remoteSelection, false)
    }
  }, [state, updateState, remoteSelection])

  const selection = state ?? remoteSelection

  return useMemo<[ReportGroupOptions[] | undefined, (state: GroupOptionsSelection) => void]>(() => {
    if (initialReportFilters == null || selection == null) return [undefined, updateState]

    const options = initialReportFilters.map((it) => ({ ...it, selected: selection[it.name] }))

    return [options, updateState]
  }, [initialReportFilters, selection, updateState])
}
