import React from 'react'

import { LabelField } from './LabelField'
import { useZeroApiContext } from '../context'
import { GqlComponent, GqlTabletField } from '../types'

interface FormFieldProps extends GqlTabletField<any> {}

export const TranslatableLabelField: GqlComponent<any, any> = (props: FormFieldProps) => {
  const { lang } = useZeroApiContext()

  return <LabelField {...props} convert={(value) => (value ? value[lang] : value)} />
}

TranslatableLabelField.skipGql = true

TranslatableLabelField.render = (props: any, value: any, row: any) => <TranslatableLabelField {...props} value={value} rowData={row} />
