import React from 'react'

import { Chip } from '@mui/material'
import { deepPurple } from '@mui/material/colors'

import { useTranslation } from '../translations'
import { FormField, GqlComponent } from '../types'
import { callOrGet, ifNull } from '../utils'


interface FormFieldProps extends FormField<any> {
  zeroLabel?: string
}

export const FormCountInfo: GqlComponent<FormFieldProps> = (props) => {
  const translate = useTranslation()

  const value = ifNull(props.value, callOrGet(props.default, props.item))

  const size = props.size === 'dense' ? 'small' : 'medium'

  // const label = props.label || translate(props.name)

  if (value === 0 && props.zeroLabel) {
    return <Chip size={size} color="warning" label={translate(props.zeroLabel)} variant="outlined" />
  }

  return <Chip size={size} label={value} variant="filled" sx={{ color: deepPurple[400] }} />
}
FormCountInfo.gql = 'Long'

FormCountInfo.render = (props: FormFieldProps, value: string) => <FormCountInfo {...props} value={value} />
