import { useCallback } from 'react'

import { MultipleBulk } from '../gql'
import { FormQueryProps } from '../types'
import { mergeDeep, objectDiff } from '../utils'

export const useCreateBulkEditMutations = (
  props: FormQueryProps,
  disaggregateColumns: Record<string, (item: any) => any[]>,
  enhanceItemValues: (item: any) => any
) => {
  const disAgrColsHash = Object.keys(disaggregateColumns).join()

  return useCallback(
    (item: any) => {
      if (!disAgrColsHash.length) throw Error('Bulk edit without disaggreate column. ' + disAgrColsHash)

      item = enhanceItemValues(item)

      const itemUpdates = objectDiff(item._agrItems?.[0], item)

      let result: any[] = []
      Object.entries(disaggregateColumns).forEach(([column, disaggregate]) => {
        if (result.length <= 0) {
          result = disaggregate(item).map((value, index) => ({
            ...(item._agrItems?.[index] ?? item),
            ...itemUpdates,
            _id: undefined,
            [column]: value
          }))
        } else {
          disaggregate(item).forEach((value, index) => {
            const itemData = result[index]
            if (!itemData) return
            itemData[column] = value
          })
        }
      })

      const prevData = item._agrItems ?? []
      const mutations: MultipleBulk = {}

      prevData.forEach((prevItem: any) => {
        const newItem = props.keys
          ? result?.find((it) => props.keys?.all((key) => it[key] === prevItem[key]))
          : result?.find((it) => it.id === prevItem.id)

        if (newItem === undefined) {
          const args = props.keys?.toMapBy(
            (it) => it,
            (it) => prevItem[it]
          ) ?? { id: prevItem.id }

          if (mutations.delete) mutations.delete.push(args)
          else mutations.delete = [args]
        } else if (JSON.stringifySafe(newItem) !== JSON.stringifySafe(prevItem)) {
          const args = props.keys?.toMapBy(
            (it) => it,
            (it) => prevItem[it]
          ) ?? { id: prevItem.id }

          const createItem = mergeDeep({ ...prevItem }, newItem, args)

          if (mutations.create) mutations.create.push(createItem)
          else mutations.create = [createItem]
        }
      })

      result.forEach((newItem: any) => {
        const prevItem = newItem
          ? props.keys
            ? prevData?.find((it: any) => props.keys?.all((key) => it[key] === newItem[key]))
            : prevData?.find((it: any) => it.id === newItem.id)
          : undefined

        if (prevItem !== undefined) return

        const createItem = mergeDeep({ ...item }, newItem)

        if (mutations.create) mutations.create.push(createItem)
        else mutations.create = [createItem]
      })

      mutations.create?.cleanUpObjects((key) => key.charAt(0) === '_')
      mutations.edit?.cleanUpObjects((key) => key.charAt(0) === '_')
      mutations.delete?.cleanUpObjects((key) => key.charAt(0) === '_')

      if (!mutations.create && !mutations.edit && !mutations.delete) return null

      return mutations
    },
    [disAgrColsHash, enhanceItemValues, disaggregateColumns, props.keys]
  )
}
