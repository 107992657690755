import { useEffect, useMemo, useRef, useState } from 'react'

import { ApolloQueryResult, NetworkStatus } from '@apollo/client'

import { useEntityFieldsFilter } from './useEntityFieldsFilter'
import { useEntityFieldsMap } from './useEntityFieldsMap'
import { useDynamicGqlMutation, useEntityRelationMutation, useEntityRelationQuery } from '../gql'
import { Field, FieldMap, FormQueryProps, GqlMutation, GqlQuery } from '../types'
import { useUID } from '../utils'

interface FormQuery {
  fields: Field<any>[]
  fieldsMap: FieldMap
  query: GqlQuery<any>
  mutation?: GqlMutation<any>
  updateRelValue?: (value: string) => void
}

export function useEntityGql(props: FormQueryProps): FormQuery {
  const entity = props.entity
  const customQuery = props.customQuery
  const readOnly = useRef(props.readOnly).current

  const entityRelFieldName = props.entityRelFieldName
  const entityRelFieldValue = props.entityRelFieldValue

  const [createdRelationValue, setCreatedRelationValue] = useState<string | undefined>()

  useEffect(() => {
    return () => setCreatedRelationValue(undefined)
  }, [entityRelFieldValue])

  const relationValue = createdRelationValue ?? entityRelFieldValue

  const fieldsMap = useEntityFieldsMap(props, relationValue)

  const fields = useMemo(() => Object.values(fieldsMap).filterNotNull(), [fieldsMap])

  const filter = useEntityFieldsFilter(props)

  const genUid = useUID()

  const relValue = relationValue || entityRelFieldValue || genUid

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keys = useMemo(() => props.keys || ['id'], props.keys ?? [])

  if (customQuery) {
    const args = fields
      .filter((it) => !it?.name?.startsWith('_'))
      .toMapBy(
        (it) => '$' + it?.name,
        (it) => it?.gql
      )

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [call, { loading }] = useDynamicGqlMutation(customQuery, args, props.args)
    const query: GqlQuery<any> = {
      items: [],
      refresh: async () =>
        ({
          data: [],
          loading: false,
          networkStatus: NetworkStatus.error
        } as ApolloQueryResult<[]>),
      isLoading: false
    }
    const mutation: GqlMutation<any> = {
      hardDeleteItem: async () => {},
      deleteItem: async () => {},
      unDeleteItem: async () => {},
      bulkEdit: async () => {},
      deepEdit: async () => {},
      saveItem: async (item) => (await call({ variables: item })).data[customQuery],
      updateItem: async (_prevItem, item) => (await call({ variables: item })).data[customQuery],
      isLoadingAction: loading
    }
    return { fields, fieldsMap, query, mutation, updateRelValue: setCreatedRelationValue }
  }

  const options = {
    keys,
    dateRangeFilter: props.dateRangeFilter,
    dateRangeField: props.dateRangeField,
    filter,
    args: props.args,
    onLoad: props.onLoad,
    single: props.list !== true,
    all: props.all,
    getParentValue: props.getParentValue
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const query = useEntityRelationQuery(customQuery || entity, fields, entityRelFieldName, relValue, {
    ...options,
    skip: (relationValue === undefined && !filter) || props.isLoading || props.item !== undefined
  })

  if (!readOnly) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const mutation = useEntityRelationMutation(customQuery || entity, fields, entityRelFieldName, relValue, options, query)
    return { fields, fieldsMap, query, mutation, updateRelValue: setCreatedRelationValue }
  }
  return { fields, fieldsMap, query, updateRelValue: setCreatedRelationValue }
}
