import React from 'react'

import { GqlComponent, GqlTabletField } from '../types'
import { callOrGet } from '../utils'

interface FormFieldProps extends GqlTabletField<any> {
  isName?: boolean
  hidden?: boolean
}

export const TemplateField: GqlComponent<any, any> = (props: FormFieldProps) => {
  const item = props.item || props.rowData || {}

  const value = callOrGet(props.value, item)

  if (item[props.name] !== value) {
    item[props.name] = value
  }
  return null
}

TemplateField.gql = 'String'

TemplateField.render = (props: any, value: any, row: any) => <TemplateField {...props} value={value} rowData={row} />
