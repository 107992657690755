import React from 'react'

import { createStyles, makeStyles } from '@mui/styles'
import FadeIn from 'react-fade-in'

import { GqlFormProgressBar } from './GqlFormProgressBar'
import { GqlFormProgressBarProps } from './types'

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      position: 'absolute',
      left: 0,
      right: 0,
      margin: 'auto',
      bottom: 0,
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ffffff80',
      zIndex: 1
    }
  })
)

interface Props extends GqlFormProgressBarProps {
  isLoading: boolean
}

export const FormLoading: React.FC<Props> = ({ isLoading, ...props }) => {
  const classes = useStyles()

  return (
    <FadeIn key="loading" transitionDuration={200} delay={100} visible={isLoading}>
      <div key="loading" className={classes.loading} style={{ top: props.topOffset || 64 }}>
        {isLoading && <GqlFormProgressBar {...props} />}
      </div>
    </FadeIn>
  )
}
