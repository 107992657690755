import { Field, GqlEntity, QueryOptions } from '../../types'
import { callOrGet, uid } from '../../utils'

export const getFieldValue = (
  parentField: GqlEntity | Field<any> | undefined,
  field: Field<any>,
  fieldData: any,
  rootData: any,
  parentData: any | undefined,
  options: QueryOptions
) => {
  if (field.forceValue) return field.forceValue

  const isNullable = field.gql && field.gql.indexOf('String') === -1

  if (fieldData === null && isNullable) return 'null'

  if (field.gql === 'Long' && fieldData === '') {
    fieldData = undefined
  }

  if (fieldData !== undefined && (fieldData != null || isNullable)) return fieldData

  if (field.id) {
    if (!parentField || !parentData) return uid()
    const foreignFieldName = parentField.name + field.name.capitalize()
    const foreignFieldData = parentData[foreignFieldName]
    if (foreignFieldData) return foreignFieldData

    if (field.valueFromParent) {
      if (typeof field.valueFromParent === 'function') {
        return field.valueFromParent(rootData)
      }
      const parentValue = parentData[field.valueFromParent]
      if (parentValue !== undefined) return parentValue
    }
    return uid()
  }

  const fieldValue = callOrGet(field.value, rootData)

  if (fieldValue !== undefined) return fieldValue

  if (field.valueFromParent) {
    if (typeof field.valueFromParent === 'function') {
      return field.valueFromParent(rootData)
    }

    if (options.getParentValue) {
      return options.getParentValue(field.valueFromParent)
    }

    return parentData ? parentData[field.valueFromParent] : undefined
  } else if (field.default) {
    return callOrGet(field.default, rootData)
  }

  if (fieldData === undefined && !field.nullable) return null

  return fieldData
}
