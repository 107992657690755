import React from 'react'

import { EditText } from './EditText'
import { NestedItemForm } from './NestedItemForm'
import { useZeroApiContext } from '../context/ZeroApiContext'
import { useTranslation } from '../translations'
import { FormField, GqlComponent } from '../types'

interface Translation {
  [key: string]: string
}

interface TranslationsProps extends FormField<Translation> {
  value?: any
  field?: string
  languages?: string[]
  labelLang?: boolean
}

// TODO: add support for proxy component like this in ItemForm
export const TranslationsField: GqlComponent<TranslationsProps, Translation> = (props) => {
  const { lang } = useZeroApiContext()
  const translate = useTranslation()

  const languages = !props.languages || props.languages.length === 0 ? [lang] : props.languages

  const name = props.field || props.name || 'name'
  const labelVal = React.isValidElement(props.label) ? props.label : translate(props.label?.toString() || 'name')
  const value = props.value || {}
  const onChange = props.onChange || (() => {})

  console.log('onChange', props.onChange)
  console.log('value', props.value)

  return (
    <NestedItemForm {...props} name={name} gql="Translation" value={value} onChange={onChange}>
      {languages.map((language) => (
        <EditText
          key={language}
          name={language.toLocaleLowerCase()}
          label={props.labelLang ? `${labelVal} (${language})` : labelVal}
          gql="String"
          default=""
          fullWidth
          readOnly={props.readOnly}
        />
      ))}
    </NestedItemForm>
  )
}
