import React from 'react'

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useTranslation } from '../translations'
import { GqlComponent, GqlTabletField } from '../types'
import { callOrGet, ifNull, preventPropagation } from '../utils'
import Show from '../utils/Show'

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    display: 'inline-grid',
    flexGrow: 1,
    minWidth: 80,
    verticalAlign: 'baseline'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

interface FormFieldProps extends GqlTabletField<string> {
  onClear?: () => void
}

export const SelectCurrency: GqlComponent<FormFieldProps, string> = (props) => {
  const classes = useStyles()
  const translate = useTranslation()

  const options = [
    { val: 'MKD', name: translate('mkd') },
    { val: 'EUR', name: translate('eur') },
    {
      val: 'USD',
      name: translate('usd')
    }
  ]

  const item = props.item ?? props.rowData
  const value = ifNull(props.value, callOrGet(props.default, item))

  const onChange = props.onChange ? props.onChange : () => {}

  return (
    <FormControl
      className={classes.formControl}
      sx={{ m: props.isForm ? 1 : 0, ml: 1, mb: 0, mt: 0 }}
      style={!props.fullWidth ? { flexGrow: 1 } : { flexGrow: 1 }}
      size={props.size !== 'dense' ? 'medium' : 'small'}
      fullWidth={props.fullWidth}
    >
      <Show if={props.isForm}>
        <InputLabel htmlFor={`select-${props.name}`} variant={props.isForm ? 'outlined' : 'standard'}>
          {React.isValidElement(props.label) ? props.label : translate(props.label?.toString() || props.name.snakeCase())}
        </InputLabel>
      </Show>
      <Select
        fullWidth={props.fullWidth}
        style={{ fontSize: props.fontSize, marginBottom: 0 }}
        value={value}
        label={
          props.isForm
            ? React.isValidElement(props.label)
              ? props.label
              : translate(props.label?.toString() || props.name.snakeCase())
            : undefined
        }
        variant={props.isForm ? 'outlined' : 'standard'}
        onKeyDown={preventPropagation}
        inputProps={{
          id: `select-${props.name}`
        }}
        error={callOrGet(props.error, value)}
        disabled={callOrGet(props.disabled, value, item)}
        onChange={(event: SelectChangeEvent<any>, _child: React.ReactNode) => {
          const option = options.find((opts) => opts.val === event.target.value)
          onChange(option?.val ? option?.val : null)
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.val} value={option.val} onKeyDown={preventPropagation} sx={{ fontSize: props.fontSize }}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
SelectCurrency.gql = 'String'
SelectCurrency.align = 'center'

const Render = (props: FormFieldProps) => {
  const translate = useTranslation()

  const options = [
    { val: 'MKD', name: translate('mkd') },
    { val: 'EUR', name: translate('eur') },
    {
      val: 'USD',
      name: translate('usd')
    }
  ]

  const propsValue = props.name && props.value && typeof props.value === 'object' ? props.value[props.name] : props.value
  const value = ifNull(propsValue, callOrGet(props.default, props.item))

  const option = options.find((opts) => opts.val === value)

  return <div style={{ display: 'inline-block' }}>{option?.name}</div>
}

SelectCurrency.render = (props: FormFieldProps, rowValue: string, row: any) => {
  return <Render {...props} value={rowValue} rowData={row} />
}
