export function keyOnlyJson(obj: any) {
  const cleaned = JSON.stringify(obj, null, 2)

  return cleaned.replace(/^[\t ]*"[^:\n\r]+":\s*([^:\n\r]+)/gm, function (match) {
    const keyVal = match.split(':')
    if (keyVal.length > 1 && keyVal[1].trim().indexOf('{') === 0) {
      return match.replace(/"/g, '').replace(/:\s*/, ' ')
    }
    return match.split(':')[0].replace(/"/g, '')
  })
}
