/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react'

import { TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import Moment from 'react-moment'
// import 'react-date-range/dist/styles.css' // main css file
// import 'react-date-range/dist/theme/default.css' // theme css file

import { useTranslation } from '../translations'
import { GqlTabletField } from '../types'
import { callOrGet } from '../utils'

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-grid',
      minWidth: '14ch',
      flexGrow: 1
    },
    field: {
      minWidth: '14ch',
      paddingBottom: 1
    }
  })
)

interface FormFieldProps extends GqlTabletField<string> {
  nameTo: string
  defaultTo?: string
  date?: boolean
  minDate?: Date | ((row: any, parentRow: any) => Date | undefined)
  maxDate?: Date | ((row: any, parentRow: any) => Date | undefined)
}

export const EditDateRange = (props: FormFieldProps) => {
  const classes = useStyles()

  const translate = useTranslation()

  const item = props.item || props.rowData || {}

  const parseDate = props.date ? (value: any) => new Date(value) : (value: any) => new Date(parseInt(value))
  const formatDate = props.date ? (value: Date) => value.toISODate() : (value: Date) => value.getTime()

  const maxDate = props.maxDate && callOrGet(props.maxDate, item, props.parentRowData)
  const minDate = props.minDate && callOrGet(props.minDate, item, props.parentRowData)

  let dateFrom = props.value ? parseDate(props.value) : 'Invalid Date'
  if (dateFrom.toString() === 'Invalid Date') dateFrom = parseDate(callOrGet(props.default, item) || '')
  if (dateFrom.toString() === 'Invalid Date' && props.valueFromParent && props.parentRowData) {
    dateFrom = new Date(props.parentRowData[callOrGet(props.valueFromParent, props.parentRowData)])
  }
  if (dateFrom.toString() === 'Invalid Date') dateFrom = minDate ?? new Date()

  let dateTo = props.value ? parseDate(item[props.nameTo]) : 'Invalid Date'
  if (dateTo.toString() === 'Invalid Date') dateTo = parseDate(props.defaultTo || '')
  if (dateTo.toString() === 'Invalid Date') dateTo = maxDate ?? new Date()

  const onChange = props.onChange ? props.onChange : (_date: string) => {}

  useEffect(() => {
    if (dateFrom && typeof dateFrom !== 'string') {
      const formatted = formatDate(dateFrom)
      if (item[props.name] !== formatted) item[props.name] = formatted
    }
    if (dateTo && typeof dateTo !== 'string') {
      const formatted = formatDate(dateTo)
      if (item[props.nameTo] !== formatted) item[props.nameTo] = formatted
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo])

  const fontSize = props.fontSize ?? (props.size === 'dense' || (!props.size && !props.isForm)) ? 12 : 16

  return (
    <DateRangePicker
      label={
        props.isForm && (React.isValidElement(props.label) ? props.label : translate(props.label?.toString() || props.name.snakeCase()))
      }
      value={[dateFrom, dateTo]}
      disabled={callOrGet(props.disabled, [dateFrom, dateTo], item)}
      className={classes.root}
      showDaysOutsideCurrentMonth
      minDate={minDate}
      maxDate={maxDate}
      closeOnSelect
      onChange={(date) => {
        if (!date) return
        const from = formatDate(date[0] || new Date())
        const to = date[1] ? formatDate(date[1]) : null
        item[props.name] = from
        item[props.nameTo] = to
        return onChange(from.toString())
      }}
      autoFocus
      format="dd-MM-yyyy"
      slots={{
        textField: (startProps: any, endProps: any) => (
          <React.Fragment>
            <TextField
              {...startProps}
              sx={{ m: props.isForm ? 1 : 0, mb: 0.5, ml: 1, fontSize }}
              className={classes.field}
              style={{ fontSize }}
              onSubmit={props.onSubmit}
              variant={props.isForm ? 'outlined' : 'standard'}
              fullWidth={props.fullWidth}
              error={callOrGet(props.error, [dateFrom, dateTo])}
            />
            <Box sx={{ mx: 1 }}> to </Box>
            <TextField
              {...endProps}
              sx={{ m: props.isForm ? 1 : 0, mb: 0.5, fontSize }}
              className={classes.field}
              onSubmit={props.onSubmit}
              variant={props.isForm ? 'outlined' : 'standard'}
              fullWidth={props.fullWidth}
              error={callOrGet(props.error, [dateFrom, dateTo])}
            />
          </React.Fragment>
        )
      }}
    />
  )
}

EditDateRange.gql = 'Long'

EditDateRange.render = (props: FormFieldProps, value: string, rowData: any) => {
  const item = props.item || props.rowData || {}

  const parseDate = props.date ? (value: any) => new Date(value) : (value: any) => new Date(parseInt(value))

  let dateFrom = value ? parseDate(value) : null
  if (!dateFrom) dateFrom = parseDate(callOrGet(props.default, item) || '')
  if (!dateFrom) dateFrom = new Date()

  let dateTo = props.nameTo ? parseDate(rowData[props.nameTo]) : null
  if (!dateTo) dateTo = parseDate(props.defaultTo || '')
  if (!dateTo) dateTo = new Date()

  // const from = dateFrom instanceof Date ? dateFrom.toLocaleDateString() : dateFrom
  // const to = dateTo instanceof Date ? dateTo.toLocaleDateString() : dateTo

  const toFormat = dateFrom?.getFullYear() === dateTo?.getFullYear() ? 'D MMM' : 'D MMM YYYY'

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      {/* @ts-ignore */}
      <Moment format="D MMM YYYY">{dateFrom}</Moment> - <Moment format={toFormat}>{dateTo}</Moment>
    </div>
  )
}
