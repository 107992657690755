import React, { createContext } from 'react'

import { ContextType, FeatureStateProvider, useReportFeature } from '../features'

export const GqlReportFiltersContext = createContext<ContextType>({
  updateState: () => {
    throw Error('Not in provider for GqlReportFiltersContext')
  }
})

export const ReportFiltersProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => (
  <FeatureStateProvider context={GqlReportFiltersContext}>{children ?? false}</FeatureStateProvider>
)

export const useReportFilters = () => useReportFeature(GqlReportFiltersContext)
