import { useMemo } from 'react'

import { getEntityFieldsMap } from './getEntityFieldsMap'
import { useZeroApiContext } from '../context'
import { FieldMap, FormQueryProps } from '../types'
import { useUID } from '../utils'

export function useEntityFieldsMap(props: FormQueryProps, relationValue?: string): FieldMap {
  const entityRelFieldName = props.entityRelFieldName
  const entityRelFieldValue = props.entityRelFieldValue

  const { lang } = useZeroApiContext()

  const genUid = useUID()

  const relValue = relationValue || entityRelFieldValue || genUid

  const aggregated = props.aggregated ?? false

  return useMemo(() => {
    return getEntityFieldsMap(
      props.children,
      props.keys,
      entityRelFieldName,
      relValue,
      aggregated,
      props.getParentValue,
      props.filter,
      lang
    )
  }, [props.children, props.keys, props.getParentValue, props.filter, entityRelFieldName, relValue, aggregated, lang])
}
