import React, { useEffect } from 'react'

import { HiddenField } from './HiddenField'
import { useTranslation } from '../translations'
import { FormField, GqlComponent, GqlTabletField } from '../types'
import { callOrGet, ifNull, useUID } from '../utils'

interface NestedItemFormProps<W> extends GqlTabletField<any, any> {
  dense?: boolean
  isLoading?: boolean
  onHasError?: (hasError: boolean) => void
  fieldWrapper?: React.FC<W> | ((props: FormField<any>) => React.FC<W> | undefined)
  fieldWrapperProps?: W | ((props: FormField<any>) => W | undefined)
}

export const NestedItemForm: GqlComponent<NestedItemFormProps<any>, string> = (props) => {
  const translate = useTranslation()

  const entityRelFieldName = props.name
  const entityRelFieldValue = props.value

  const fieldsMap: Record<string, FormField<any>> = {}

  const keys = props.keys || ['id']

  const genUid = useUID()

  const relValue = entityRelFieldValue || genUid

  keys.forEach((key) => {
    fieldsMap[key] = {
      name: key,
      gql: 'String!',
      id: key === 'id',
      value: entityRelFieldName === key ? relValue : genUid
    }
  })

  React.Children.forEach(props.children, (child) => {
    if (child === undefined || !React.isValidElement(child)) return
    const props = child.props as FormField<any>

    const childType = child.type as GqlComponent<any>

    const gqlType = props.gql || childType.gql
    if (gqlType === undefined) console.warn('gql-type not defined in ', childType.name)

    fieldsMap[props.name] = {
      name: props.name,
      default: props.default,
      value: props.value,
      hidden: callOrGet(props.hidden),
      gql: props.nullable ? gqlType : `${gqlType}!`
    }
  })

  const fields = Object.values(fieldsMap)

  const data =
    props.value ||
    fields.toMapBy(
      (field) => field.name,
      (field) => callOrGet(field.value) ?? callOrGet(field.default)
    )

  const itemData = props.value
  const setItemData = props.onChange

  const item = itemData || data

  const onChange = (name: string) => (value: any, itemUpdates?: any) => {
    if (!setItemData) return
    setItemData({ ...item, ...itemUpdates, [name]: value, _orgState: item._orgState || item })
  }

  let hasError = false
  const children = React.Children.map(props.children, (child) => {
    if (!React.isValidElement(child)) return null

    const childType = child.type as GqlComponent<any>

    const childProps = child.props as FormField<any>

    if (childType === HiddenField && !childProps.valueFromParent && typeof childProps.value !== 'function') return null

    const fieldName = childProps.name
    if (fieldName === undefined) return child

    const field = fieldsMap[fieldName]
    if (field === undefined) return child

    const value = item[fieldName] ?? field.value

    if (callOrGet(childProps.hidden, value, item) === true) return null

    const error = value === undefined || value?.length === 0 || callOrGet(childType.validate, childProps, value)

    if (error) hasError = true

    const WrapperComp = callOrGet(props.fieldWrapper, childProps)

    const formFieldProps: FormField<any> = {
      name: childProps.name,
      key: childProps.key || fieldName,
      label: translate(childProps?.label?.toString() ?? fieldName ?? ''),
      isForm: props.isForm,
      inTable: props.inTable,
      size: props.size,
      fontSize: props.fontSize,
      value,
      hidden: false,
      item,
      disabled: childProps.disabled || props.isLoading,
      error,
      onChange: onChange(fieldName),
      onSubmit: props.onSubmit,
      style: {
        flexGrow: 1
      },
      parentRowData: props.parentRowData,
      fullWidth: props.fullWidth || WrapperComp != null
    }

    const childElement = React.cloneElement(child, formFieldProps, childProps.children)

    if (!WrapperComp || childType === HiddenField) return childElement

    const wrapperCompProps = callOrGet(props.fieldWrapperProps, childProps)

    return <WrapperComp {...wrapperCompProps}>{childElement}</WrapperComp>
  })

  useEffect(() => {
    if (props.onHasError) props.onHasError(hasError)
  }, [hasError, props])

  return <React.Fragment>{children ?? null}</React.Fragment>
}
