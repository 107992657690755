import { useCallback } from 'react'

import { FieldValueGetter } from './types'
import { FieldMap, FormQueryProps } from '../types'

export const useEnhanceItemValues = (props: FormQueryProps, fieldsMap: FieldMap, fieldValueGetters: FieldValueGetter[]) => {
  return useCallback(
    (item: any) => {
      const keys = props.keys ?? ['id']
      keys.forEach((key) => {
        const field = fieldsMap[key]
        if (!field) return
        if (field.forceValue != null) {
          item[key] = field.forceValue
        } else if (field.value != null) {
          item[key] = field.value
        } else if (field.valueFromParent && props.getParentValue) {
          item[key] = props.getParentValue(field.valueFromParent)
        }
      })

      fieldValueGetters.forEach((value) => {
        if (!value.isValueFromParent) return
        if (!item[value.fieldPathName]) {
          item[value.fieldPathName] = value.getValue(item)
        }
      })

      return item
    },
    [fieldValueGetters, fieldsMap, props]
  )
}
