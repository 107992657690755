// noinspection JSUnusedGlobalSymbols

import { useCallback, useRef, useState } from 'react'

import moment from 'moment'

export function atMost(value: moment.Moment | undefined, maxValue: moment.Moment) {
  return !value || value > maxValue ? maxValue : value
}

export function atLeast(value: moment.Moment | undefined, minValue: moment.Moment) {
  return !value || value < minValue ? minValue : value
}

export function between(value: moment.Moment | undefined, minValue: moment.Moment, maxValue: moment.Moment) {
  if (!value) return minValue
  if (value < minValue) return minValue
  if (value > maxValue) return maxValue
  return value
}

export function greatest<T>(...values: T[]) {
  let max = values[0]
  for (let i = 1; i < values.length; i++) {
    const val = values[i]
    if (val > max) max = val
  }
  return max
}

export function lowest<T>(...values: T[]) {
  let min = values[0]
  for (let i = 1; i < values.length; i++) {
    const val = values[i]
    if (val < min) min = val
  }
  return min
}

export const printDiv = (printPage: any, noPrint?: string, newWindow?: boolean) => {
  // let oldHtml = document.body.outerHTML;
  const elements = Array.from(
    document.querySelectorAll('[class*="MTableToolbar-searchField"],[class*="MTableToolbar-actions"]')
  ) as HTMLElement[]
  const elementsDisplay = elements.map((el) => (el as HTMLElement).style.display)
  elements.forEach((el) => ((el as HTMLElement).style.display = 'none'))

  const hideElems = (noPrint !== undefined && noPrint.length > 0 ? document.querySelectorAll(`.${noPrint}`) : []) as HTMLElement[]

  hideElems.forEach((el) => {
    if (typeof el.style !== 'undefined') {
      el.style.display = 'none'
    }
  })

  const printContent: string | undefined = document.getElementById(printPage)?.innerHTML
  const content = printContent ?? 'error'

  if (newWindow) {
    const w = window.open()
    if (w !== null) {
      w.document.head.outerHTML = document.head.outerHTML
      w.document.body.innerHTML = content
      w.print()

      setTimeout(() => {
        w.close()
      }, 100)

      hideElems.forEach((el) => {
        if (typeof el.style !== 'undefined') {
          el.style.display = 'none'
        }
      })
      return true
    }
  }

  document.body.innerHTML = content
  window.print()

  setTimeout(() => {
    document.location.reload()
  }, 100)

  elements.forEach((el, idx) => {
    el.style.display = elementsDisplay[idx] || 'block'
  })

  return true
}

export const NO_DIFF = {}

export const objectDiff: (first: any, second: any) => any = (first, second) => {
  if (first == null || second == null) return second
  if (typeof first !== 'object' || typeof second !== 'object') {
    return first === second ? NO_DIFF : second
  }

  if (!Object.keys(first).length && !Object.keys(second).length) return NO_DIFF

  let result: any | undefined

  Object.entries(first).forEach(([key, value]) => {
    const diff = objectDiff(value, second[key])
    if (diff === NO_DIFF) return
    if (result) {
      result[key] = diff
    } else {
      result = { [key]: diff }
    }
  })

  return result || NO_DIFF
}

type FilterCallback = (key: string, value: any) => boolean

export const getObjectChanges: (first: any, second: any, filterOut?: FilterCallback) => any = (first, second, filterOut) => {
  if (first === second) return NO_DIFF
  if (second === undefined) return first
  if (first == null || second == null) return second
  if (typeof first !== 'object' || typeof second !== 'object') {
    return first === second ? NO_DIFF : second
  }

  if (!Object.keys(first).length && !Object.keys(second).length) return NO_DIFF

  let result: any | undefined

  Object.entries(second).forEach(([key, value]) => {
    if (filterOut && filterOut(key, value)) return
    const diff = getObjectChanges(first[key], value, filterOut)
    if (diff === NO_DIFF) return
    if (result) {
      result[key] = diff
    } else {
      result = { [key]: diff }
    }
  })

  return result ?? NO_DIFF
}

export const simplify: (obj: any, glue?: string, path?: string, result?: any) => any = (obj, glue = '_', path = '', result = {}) => {
  if (typeof obj !== 'object') return {}

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value !== 'object') {
      result[`${path}${key}`] = typeof value !== 'undefined' ? (value as any).toString() : value
    } else {
      simplify(value, glue, `${path}${key}${glue}`, result)
    }
  })

  return result
}

const isObject = (item: any) => {
  return item && typeof item === 'object' && !Array.isArray(item)
}

export function mergeDeep(target: any, ...sources: any): any {
  if (!sources.length) return target
  const source = sources.shift()
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      const sourceValue = source[key]
      if (isObject(sourceValue)) {
        const targetVal = { ...target[key] }
        target[key] = targetVal
        mergeDeep(targetVal, sourceValue)
      } else {
        Object.assign(target, { [key]: sourceValue })
      }
    }
  }
  return mergeDeep(target, ...sources)
}

export const setNestedValue = (item: any, fieldPath: string, value: any) => {
  const path = fieldPath.split('.')
  if (path.length === 1) {
    item[fieldPath] = value
  } else {
    let aggrItemValue = item
    const lastIndex = path.length - 1
    for (let i = 0; i < lastIndex; i++) {
      const pathPart = path[i]
      if (aggrItemValue == null) {
        const aggrItemValueTmp = {}
        aggrItemValue[pathPart] = aggrItemValueTmp
        aggrItemValue = aggrItemValueTmp
      } else {
        const aggrItemValueTmp = { ...aggrItemValue[pathPart] }
        aggrItemValue[pathPart] = aggrItemValueTmp
        aggrItemValue = aggrItemValueTmp
      }
    }
    aggrItemValue[path[lastIndex]] = value
  }
  return item
}

const NULL_OBJ = {}

export const getNestedValue = (item: any, fieldPath: string) => {
  const path = fieldPath.split('.')

  if (path.length === 1) return item[fieldPath]

  for (const pathPart of path) {
    if (!(pathPart in item) && Array.isArray(item)) {
      item = item.flatMap((it) => it[pathPart] ?? {})
    } else {
      item = item[pathPart] ?? NULL_OBJ
    }
  }
  if (item === NULL_OBJ) return null

  return item
}

export const isObjectMatching = (source: any, target: any) => {
  if (source === target) return true
  if (source == null || target == null) return false
  return Object.entries(source).all(([key, value]) => key.charAt(0) === '_' || target[key] === value)
}

export const cleanUpObject = (item: any, predicate: (key: string) => boolean = (key) => key.charAt(0) === '_') => {
  const object = { ...item }
  Object.keys(object).forEach((key) => {
    if (predicate(key)) {
      delete object[key]
      return
    }
    const value = object[key]
    if (value == null) {
      return
    }
    if (Array.isArray(value)) {
      object[key] = value.map((it) => cleanUpObject(it, predicate))
    } else if (typeof value === 'object') {
      cleanUpObject(value, predicate)
    }
  })
  return object
}

export const preventPropagation = (event: any, isOpen?: boolean) => {
  if (isOpen === false) return
  if (event.key === 'Enter') {
    event.stopPropagation()
  }
}

export const ifNull: <T>(...params: T[]) => T | null | undefined = (...params) => {
  return params.find((param) => param !== undefined && param !== null)
}

export const callOrGet: <T>(value: T | ((...args: any[]) => T), ...args: any[]) => any = (value, ...args) => {
  if (value instanceof Function) return value(...args)
  return value
}

export const parseInteger = (value: any) => {
  const result = parseInt(value)
  if (isNaN(result)) return ''
  return result
}

export const parseDecimal = (value: any) => {
  const result = parseFloat(value)
  if (isNaN(result)) return ''
  return result
}

export const switchCase = (...cases: (any | boolean)[]) => {
  const count = cases.length
  for (let i = 0; i < count; i++) {
    const caseStatement = cases[i]
    if (i === count - 1) return caseStatement

    const outcomes = cases[i + 1]
    if (outcomes === undefined) return

    // eslint-disable-next-line no-prototype-builtins
    if (outcomes.hasOwnProperty(caseStatement)) {
      return outcomes[caseStatement]
    }
  }
}

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const useResetableState = <T>(initialValue: T, deps: any[], setFirstNonNull = false): [T, (newValue: T) => void] => {
  const valueRef = useRef(initialValue)
  const prevDepsRef = useRef(deps)
  const setState = useState(0)[1]

  const setValue = useCallback(
    (newValue: T | ((value: T) => T)) => {
      if (newValue instanceof Function) {
        valueRef.current = newValue(valueRef.current)
      } else {
        valueRef.current = newValue
      }

      setState((prevState) => prevState + 1)
    },
    [setState]
  )

  if (prevDepsRef.current.some((prevDep, index) => prevDep !== deps[index])) {
    valueRef.current = initialValue
    prevDepsRef.current = deps
  } else if (setFirstNonNull && valueRef.current == null && initialValue != null) {
    valueRef.current = initialValue
    prevDepsRef.current = deps
  }

  return [valueRef.current, setValue]
}

type SetState<T> = (newValue?: T | ((value?: T) => T), silent?: boolean) => void
type UseState<T> = [T | undefined, SetState<T>]

export const useSimeReactiveState = <T>(initialValue?: T): UseState<T> => {
  const valueRef = useRef(initialValue)
  const setState = useState(0)[1]

  const setValue = useCallback<SetState<T>>(
    (newValue, reRender = true) => {
      const newState = newValue instanceof Function ? newValue(valueRef.current) : newValue

      if (valueRef.current === newState) return

      valueRef.current = newState

      if (reRender) setState((prevState) => prevState + 1)
    },
    [setState]
  )

  const value = valueRef.current

  const resultRef = useRef<[T | undefined, (newValue?: T) => void]>()
  const lastResult = resultRef.current
  if (lastResult && lastResult[0] === value && lastResult[1] === setValue) {
    return lastResult
  }

  const result: UseState<T> = [value, setValue]
  resultRef.current = result
  return result
}
