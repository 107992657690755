import React, { useEffect, useMemo, useState } from 'react'

import { Autocomplete, autocompleteClasses, Popper, TextField, Theme } from '@mui/material'
import { makeStyles, styled } from '@mui/styles'

import { HiddenField } from './HiddenField'
import { useCustomQuery } from '../gql'
import { useTranslation } from '../translations'
import { GqlComponent, GqlTabletField } from '../types'
import { callOrGet, getNestedValue, ifNull, preventPropagation, useResetableState } from '../utils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-grid',
    margin: theme.spacing(1),
    minWidth: '18ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      display: 'grid',
      width: '-webkit-fill-available'
    }
  },
  endAddorment: {
    opacity: 0.7,
    fontSize: 11,
    whiteSpace: 'nowrap',
    marginLeft: -30
    // marginRight: -36,
    // paddingRight: 8,
    // transition: '0.15s padding ease-out',
    // '&:hover': { paddingRight: 36 }
  }
}))

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    },
    '& li': {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
      paddingBottom: 4,
      margin: 0,
      fontSize: 11.5,
      fontWeight: 400,
      whiteSpace: 'nowrap',
      textAlign: 'right'
    }
  }
})

interface CurrencyOption {
  from: string
  to: string
  rate: number
}

interface FormFieldProps extends GqlTabletField<number> {
  currencyField: string
  toCurrency?: string
  toCurrencyField?: string
  exchRate?: string
  exchRateField?: string
  dateField?: string
  date?: string
  exchRateMethod: {
    name: string
    paramToCurrency?: string
    paramDate?: string
    propCurrency?: string
    propRate?: string
  }
  showCurrency?: boolean
  showConversion?: boolean
}

export const RenderCurrencyAmountField: React.FC<FormFieldProps> = (props: FormFieldProps) => {
  const item = props.rowData ?? props.item
  const currency = item[props.currencyField]?.toString()
  const toCurrency = props.toCurrency ?? (props.toCurrencyField ? getNestedValue(item, props.toCurrencyField) : undefined)
  const exchRate = props.exchRate ?? (props.exchRateField ? getNestedValue(item, props.exchRateField) : 1)

  const propsValue = props.name && props.value && typeof props.value === 'object' ? props.value[props.name] : props.value
  const value = propsValue ?? callOrGet(props.default, props.item)
  if (currency === undefined || !toCurrency) {
    return <div style={{ display: 'inline-block' }}>{value.formatCurrency(toCurrency ?? currency)}</div>
  }

  if (!currency || !exchRate || currency === toCurrency) {
    return <div style={{ display: 'inline-block' }}>{value.formatCurrency(currency || toCurrency)}</div>
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <span style={{ opacity: 0.7 }}>{value.formatCurrency(currency)} = </span>
      {(value * exchRate).formatCurrency(toCurrency)}
    </div>
  )
}

export const CurrencyAmountAutoComplete: GqlComponent<FormFieldProps, number> = (props: FormFieldProps) => {
  const classes = useStyles()

  const translate = useTranslation()

  const item = props.item || props.rowData
  const propsValue = props.name && props.value && typeof props.value === 'object' ? props.value[props.name] : props.value
  const value = ifNull(propsValue, callOrGet(props.default, item))

  const currency: string | undefined = item[props.currencyField]?.toString()

  const [isOpen, setIsOpen] = useState(false)
  const [rawInputValue, setRawInputValue] = useResetableState<string>(currency ? `${value} ${currency}` : value.toString(), [item.id])

  const onChange = props.onChange !== undefined ? props.onChange : () => {}

  const date = props.date ?? (props.dateField ? item[props.dateField] : undefined)
  const toCurrency: string = ((props.toCurrencyField ? item[props.toCurrencyField] : undefined) ?? props.toCurrency)?.toString() ?? ''

  const exchRateFields = useMemo(() => {
    return [
      { name: 'id', gql: 'String' },
      {
        name: props.exchRateMethod?.propCurrency ?? 'currency',
        gql: 'String'
      },
      { name: props.exchRateMethod?.propRate ?? 'rate', gql: 'Float' }
    ]
  }, [props.exchRateMethod?.propCurrency, props.exchRateMethod?.propRate])

  const { items: currencies, isLoading } = useCustomQuery(props.exchRateMethod.name, exchRateFields, undefined, undefined, {
    args: { [props.exchRateMethod.paramDate ?? 'date']: date, [props.exchRateMethod.paramToCurrency ?? 'toCurrency']: toCurrency },
    skip: !date || !toCurrency,
    fetchPolicy: 'network-only'
  })

  const valueParts = Array.from(rawInputValue.matchAll(/[\d\\.]+|\w+|\s+/g)).map((it) => it[0].trim())
  const valuePartsNoEmpty = valueParts.filter((it) => it.length)
  const valuePart = valueParts[0]
  const currencyPart = valuePartsNoEmpty[1] ?? valueParts[1]
  const inputAmount = parseFloat(valuePart)
  const inputCurrency = isNaN(inputAmount) || currencyPart == null ? toCurrency : currencyPart.cyrillicToLatin()

  const options: CurrencyOption[] = useMemo(() => {
    if (inputCurrency !== undefined && currencies !== undefined) {
      return currencies.map(
        (item) =>
          ({
            to: toCurrency,
            from: item.currency.toString(),
            rate: item.rate as number
          } as CurrencyOption)
      )
    }

    return [{ to: toCurrency, from: toCurrency, rate: 1 }]
  }, [currencies, inputCurrency, toCurrency])

  const selected: CurrencyOption | undefined = useMemo(() => {
    if (toCurrency && options) {
      return options.find((it) => it.from.cyrillicToLatin() === inputCurrency)
    }
    return undefined
  }, [inputCurrency, options, toCurrency])

  const fieldSize = props.size === 'dense' ? 'small' : 'medium'

  const inputValue = useMemo(() => {
    if (selected && inputCurrency) {
      return `${valuePart} ${inputCurrency?.toUpperCase()}`
    }
    if (!isOpen && props.showCurrency && (inputCurrency ?? currency)) {
      return `${valuePart} ${(inputCurrency ?? currency)?.toUpperCase()}`
    }
    return rawInputValue
  }, [isOpen, selected, inputCurrency, valuePart, rawInputValue, props.showCurrency, currency])

  const getOptionLabel = (option?: string | CurrencyOption) => {
    if (typeof option === 'string' || !option?.rate) return inputValue.toString()
    if (option.from === option.to) return inputAmount.toString()
    return `${inputAmount.formatCurrency(option.from).replace(/([\d.,]+)/g, '$1 ')} = ${(inputAmount * option.rate).formatCurrency(
      option.to
    )}`
  }

  useEffect(() => {
    if (isNaN(inputAmount)) return
    item[props.currencyField] = selected?.from

    const exchRateField = props.exchRateField
    if (exchRateField) {
      const selectedRate = selected?.rate ?? 1
      if (propsValue !== inputAmount || item[exchRateField] !== selectedRate) {
        item[exchRateField] = selectedRate
        item[props.name] = inputAmount
        onChange(inputAmount, { [exchRateField]: selectedRate })
      }
    } else {
      onChange(inputAmount)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected?.rate, toCurrency, inputAmount])

  const endAdornment = useMemo(() => {
    if (props.showConversion && selected?.rate && selected?.rate !== 1 && inputAmount) {
      return <span className={classes.endAddorment}>= {(inputAmount * selected.rate).formatCurrency(toCurrency)}</span>
    }
    return null
  }, [classes.endAddorment, inputAmount, props.showConversion, selected, toCurrency])

  return (
    <Autocomplete
      className={classes.root}
      onOpen={() => {
        setIsOpen(true)
      }}
      onClose={() => {
        setIsOpen(false)
      }}
      loading={!!date && !!toCurrency && isLoading}
      PopperComponent={StyledPopper}
      fullWidth={props.fullWidth}
      options={options ?? []}
      inputValue={inputValue}
      onChange={(_event, value) => {
        if (typeof value === 'string') {
          if (isNaN(inputAmount)) {
            setRawInputValue('')
          } else {
            setRawInputValue(inputAmount.toString())
          }
          return
        }
        setRawInputValue(`${inputAmount} ${(value as any).from}`)
      }}
      onInputChange={(_e, value) => {
        if (value.indexOf('=') === -1) setRawInputValue(value)
      }}
      onSubmit={props.onSubmit}
      disabled={callOrGet(props.disabled, value, item)}
      size={fieldSize}
      onKeyDown={(event) => preventPropagation(event, isOpen)}
      filterOptions={(options: CurrencyOption[]) => {
        if (inputCurrency === undefined) return []
        if (inputCurrency.length === 0) return options
        return options?.filter((it) => it.from.cyrillicToLatin().indexOf(inputCurrency) > -1)
      }}
      isOptionEqualToValue={(option, value) =>
        (value && typeof value !== 'string' && typeof option !== 'string' && option?.from === value.from && option.to === value.to) || false
      }
      getOptionLabel={getOptionLabel}
      renderInput={(params) => {
        params.inputProps.autoCorrect = 'off'
        params.inputProps.autoCapitalize = 'off'
        params.inputProps.style = { fontSize: props.fontSize ?? props.size === 'dense' ? 12 : 14 }
        return (
          <TextField
            {...params}
            label={
              props.isForm
                ? React.isValidElement(props.label)
                  ? props.label
                  : translate(props.label?.toString() || props.name.snakeCase())
                : undefined
            }
            error={callOrGet(props.error, inputValue)}
            variant={props.isForm ? 'outlined' : 'standard'}
            size={fieldSize}
            fullWidth={props.fullWidth}
            InputProps={{
              ...params.InputProps,
              startAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
              endAdornment
            }}
          />
        )
      }}
      handleHomeEndKeys
      autoHighlight
      selectOnFocus
      freeSolo
    />
  )
}
CurrencyAmountAutoComplete.fields = (props) => {
  return [
    <HiddenField key="amount" name={props.name} nullable={props.nullable} gql={props.gql || 'Float'} />,
    <HiddenField key="currency" name={props.currencyField || 'currency'} nullable={props.nullable} gql="String" />,
    <HiddenField key="exchRate" name={props.exchRateField || 'exchRate'} nullable={props.nullable} gql="Float" />
  ]
}
CurrencyAmountAutoComplete.gql = 'Float'
CurrencyAmountAutoComplete.align = 'right'

CurrencyAmountAutoComplete.render = (props: FormFieldProps, rowValue: number, row: any) => {
  return <RenderCurrencyAmountField {...props} value={rowValue} rowData={row} />
}
