import React from 'react'

import { GqlReportFiltersContext } from './Context'
import { ReportFilterView } from './ReportFilterView'
import { useTranslation } from '../../../translations'
import { useFeature } from '../features'
import { GqlZeroReport, ReportFilter } from '../types'
import { ReportControls } from '../ui/ReportControls'

interface Props {
  report?: GqlZeroReport<any>
  icon?: React.ReactNode
}

export const GqlReportFilters: React.FC<Props> = ({ report, icon }) => {
  const translate = useTranslation()

  const [filters, selection, updateFilters] = useFeature<ReportFilter>(GqlReportFiltersContext, report?.filters)

  if (!filters) return null

  return (
    <ReportControls title={translate('filters')} icon={icon} defaultExpanded>
      {filters.map((reportFilter) => (
        <ReportFilterView key={reportFilter.name} reportFilter={reportFilter} filters={selection} updateFilters={updateFilters} />
      ))}
    </ReportControls>
  )
}
