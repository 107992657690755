import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'

interface ModuleTranslation {
  [key: string]: string | string[]
}

export interface Translations {
  [key: string]: ModuleTranslation | string | string[]
}

interface TranslationContextInfo {
  locale: string
  translations: Translations
  changeLocale: (locale: string) => Promise<void>
  translate: (keyPath: string, ...args: string[]) => string
  translatePlural: (keyPath: string, count: number, ...args: string[]) => string
}

const TranslationsContext = React.createContext<TranslationContextInfo>({
  locale: 'en',
  translations: {},
  changeLocale: async () => {},
  translate: () => {
    console.warn('Missing TranslationProvider')
    return ''
  },
  translatePlural: () => {
    console.warn('Missing TranslationProvider')
    return ''
  }
})

interface TranslationsProviderProps {
  defaultTranslations: Translations
  defaultLocale: string
  locale?: string
  translationsFactory: (locale: string) => Promise<Translations>
}

const getStringTranslate = (translation: string | string[], ...args: string[]) => {
  if (translation instanceof Array) {
    translation = translation[0]
  }
  if (!args.length) return translation
  return args.reduce((result, param) => result.replace('%s', param), translation)
}

const getCountTranslate = (translation: string | string[], count: number, ...args: string[]) => {
  if (translation instanceof Array) {
    if (!count) {
      translation = translation[0]
    } else if (count === 1) {
      translation = translation[1]
    } else {
      translation = translation[2]
    }
  }

  if (!args) return translation
  return args.reduce((result, param) => result.replace('%s', param), translation)
}

export const TranslationProvider: React.FC<PropsWithChildren<TranslationsProviderProps>> = ({
  defaultTranslations,
  defaultLocale,
  translationsFactory,
  children,
  locale
}) => {
  const [{ translations, userLocale }, setState] = useState({ translations: defaultTranslations, userLocale: defaultLocale })

  const changeLocale = useCallback(
    async (newLocale: string) => {
      setState({
        translations: await translationsFactory(newLocale),
        userLocale: newLocale
      })
    },
    [translationsFactory]
  )

  useEffect(() => {
    if (locale) changeLocale(locale)
  }, [changeLocale, locale])

  const translatePlural = (keyPath: string, count: number, ...args: string[]) => {
    const [module, key] = keyPath.split('.')
    const translationValue = (key ? translations[module]?.[key] : translations[module]) ?? keyPath
    return getCountTranslate(translationValue, count, ...args)
  }

  const translate = (keyPath: string, ...args: string[]) => {
    const [module, key] = keyPath.split('.')
    const translationValue = (key ? translations[module]?.[key] : translations[module]) ?? keyPath
    return getStringTranslate(translationValue, ...args)
  }

  const contextState = { translations, locale: userLocale, changeLocale, translatePlural, translate }
  return <TranslationsContext.Provider value={contextState}>{children}</TranslationsContext.Provider>
}

export const useCurrentLocale = () => useContext(TranslationsContext).locale
export const useTranslation = () => useContext(TranslationsContext).translate
export const usePluralTranslation = () => useContext(TranslationsContext).translatePlural

export const useTranslations = (module: string) => {
  const { translations, locale } = useContext(TranslationsContext)
  return useMemo(() => {
    if (!translations) {
      console.debug('Translations not loaded', locale)
      return '...'
    }

    const moduleTranslations = translations[module]

    return {
      translate: (key: keyof typeof moduleTranslations, ...args: string[]) => {
        if (!moduleTranslations) {
          console.debug('Missing translations for module ' + module, locale)
          return '..'
        }

        const translationValue = moduleTranslations[key] ?? key
        return getStringTranslate(translationValue, ...args)
      },
      translatePlural: (key: keyof typeof moduleTranslations, count: number, ...args: string[]) => {
        if (!moduleTranslations) {
          console.warn('Missing translations for module ' + module, locale)
          return '..'
        }

        const translationValue = moduleTranslations[key] ?? key
        return getCountTranslate(translationValue, count, ...args)
      }
    }
  }, [translations, module, locale])
}
