import React from 'react'

import { HiddenField } from './HiddenField'
import { FormField, GqlComponent, GqlSelection } from '../types'


interface FormFieldProps extends FormField<any> {
  rowData?: any
  param?: any
  fields?: GqlSelection
  render: (row: any) => string | JSX.Element
}

export const FormMultiField: GqlComponent<FormFieldProps, any, string | JSX.Element> = (props) => {
  return props.render(props.rowData || props.item || {})
}

FormMultiField.readOnly = true
FormMultiField.gql = 'String'
FormMultiField.fields = (props) => {
  const fields = props.fields
  if (!fields) return []
  return (
    Object.keys(fields)
      // eslint-disable-next-line no-prototype-builtins
      .filter((field) => fields.hasOwnProperty(field))
      .map((field) => {
        const value = fields[field]
        if (value === true) {
          return <HiddenField key={field} name={field} gql="String" nullable />
        }
        return <HiddenField key={field} name={field} gql="String" subSelection={value} nullable />
      })
  )
}

FormMultiField.render = (props, _value, row) => props.render(row)
