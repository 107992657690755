import React from 'react'

import { Box } from '@mui/system'

import { ItemData } from '../types'

export interface GqlDetailPanelProps {
  children: JSX.Element
  rowData?: any
  disabled?: boolean
  refreshParent?: boolean
  icon?: string | React.ComponentType<any>
  openIcon?: string | React.ComponentType<any>
  tooltip?: string
  refresh?: (data: ItemData) => Promise<any> | boolean | undefined
}

export const GqlDetailPanel: React.FC<GqlDetailPanelProps> = ({ children, rowData, refresh, refreshParent }) => {
  return (
    <Box
      sx={{
        // backgroundColor: "#eaf1f1",
        backgroundColor: '#fff',
        boxShadow: '0px 6px 8px -4px',
        marginBottom: 1.5,
        '& > div > div > div > div': {
          paddingLeft: 2,
          paddingTop: 0,
          paddingBottom: 2
        }
      }}
    >
      {React.cloneElement(children, refreshParent ? { refresh, ...children.props, rowData } : { ...children.props, rowData })}
    </Box>
  )
}
