import { useMemo } from 'react'

import { ReportGroupOptions } from '../types'

export const useReportItemGroups = (groupOptions?: ReportGroupOptions[]) => {
  return useMemo(() => {
    if (!groupOptions?.length) return undefined

    return groupOptions.filter((it) => it.selected).map((it) => it.name)
  }, [groupOptions])
}
