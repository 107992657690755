import { ExportOption } from '../table'

export let DEFAULT_EXPORT_OPTIONS: ExportOption<any>[] | undefined

export const FontSizes = {
  small: 11,
  normal: 12,
  large: 14
}

export const DenseTypes = {
  dense: [8, 2, 8, 2],
  normal: [16, 6, 16, 6],
  large: [16, 16, 16, 16]
}

export const HeaderFontSizes = {
  small: 12,
  normal: 13,
  large: 15
}

export const HeaderGroupDenseTypes = {
  dense: [8, 4, 8, 4],
  normal: [16, 6, 16, 6],
  large: [16, 10, 10, 10]
}
