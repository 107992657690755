import { useState } from 'react'

let sequence = 0
const init = new Date().getTime() - 1681863400000
const seed = Math.floor(Math.random() * 1000)

export function uid() {
  return (init * 100 + seed).toString() + (sequence++).toString().padStart(5, '0')
}

export const useUID = () => useState(() => uid())[0]
