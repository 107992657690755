import { processFieldsData } from './processFieldsData'
import { Field, GqlEntity, QueryOptions } from '../../types'
import { GqlQueryInput } from '../types'

export const generateItemUpdateGql = (
  entityName: string,
  gqlFields: Field<any>[],
  prevData: Record<string, any> | undefined,
  newData: Record<string, any>,
  options: QueryOptions = {}
): Record<string, GqlQueryInput> | undefined => {
  const entity: GqlEntity = { name: entityName, keys: options.keys ?? ['id'], root: true }

  const [queries, explicitQueries] = processFieldsData(entity, gqlFields, prevData, newData, newData, undefined, {
    ...options,
    queryIdx: 0
  })

  if (!queries && !explicitQueries) return undefined

  return { ...queries, ...explicitQueries }
}
