import React, { memo } from 'react'

import { GqlReportProps } from './GqlReport'
import { PlainTable, PlainTableProps } from '../../table'

const compareDependencies = (prev?: any[], next?: any[]) => {
  if (prev === next) return true
  if (prev === undefined || next === undefined) return false
  for (let i = 0; i < prev.length; i++) {
    if (prev[i] !== next[i]) return false
  }
  return true
}

export const PlainTableMemo = memo(
  (props: PlainTableProps<any> & GqlReportProps) => <PlainTable {...props} />,
  (prev, next) =>
    prev.lang === next.lang &&
    prev.items === next.items &&
    prev.dense === next.dense &&
    prev.fontSize === next.fontSize &&
    prev.noPaging === next.noPaging &&
    prev.hasBack === next.hasBack &&
    prev.aggregated === next.aggregated &&
    prev.readOnly === next.readOnly &&
    prev.columns === next.columns &&
    prev.treeItemsLookup === next.treeItemsLookup &&
    compareDependencies(prev.deps, next.deps)
)
