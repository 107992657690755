import { getFieldValue } from './getFieldValue'
import { Field, GqlEntity, QueryOptions } from '../../types'

export const processFieldsData = (
  parentField: GqlEntity | Field<any>,
  gqlFields: Field<any>[],
  prevData: Record<string, any> | undefined,
  newData: Record<string, any>,
  rootData: Record<string, any>,
  parentData: Record<string, any> | undefined,
  options: QueryOptions & { queryIdx: number } = { queryIdx: 0 }
) => {
  let inputParams: any | undefined
  let rootQueries: any | undefined

  let hasInputValues = false
  let hasNestedUpdates = false

  const keys = parentField.keys ?? ['id']

  for (const field of gqlFields) {
    if (field.virtual || field.readOnly || field.list) continue

    const prevFieldData = prevData ? prevData[field.name] : undefined
    const newFieldData = newData ? newData[field.name] : undefined

    if (field.nested || field.dependent) {
      if (!field.childs) throw Error('field.nested = true, requires children')

      if (prevFieldData == null && newFieldData == null && field.nullable) continue

      const entityName = field.dependent ? field.gql?.replace('!', '') ?? field.name : field.name
      const entity = { name: entityName, keys: field.keys ?? ['id'], dependent: field.dependent }
      const childs = Object.values(field.childs).filterNotNull()

      if (field.list) {
        newFieldData.forEach((newFieldItem, index) => {
          const prevFieldItem = prevFieldData ? prevFieldData[index] : undefined

          const [queries, explicitQueries] = processFieldsData(entity, childs, prevFieldItem, newFieldItem, field, inputParams, options)

          if (queries) {
            inputParams = { ...inputParams, ...queries }
            hasNestedUpdates = true
          }
          if (explicitQueries) rootQueries = { ...rootQueries, ...explicitQueries }
        })
      } else {
        const [queries, explicitQueries] = processFieldsData(entity, childs, prevFieldData, newFieldData, field, inputParams, options)

        if (queries) {
          inputParams = { ...inputParams, ...queries }
          hasNestedUpdates = true
        }
        if (explicitQueries) rootQueries = { ...rootQueries, ...explicitQueries }
      }
    } else {
      const isKey = field.id ?? keys.includes(field.name)

      const value = getFieldValue(parentField, field, newFieldData, rootData, parentData, options)

      const isChanged = !prevData || value !== prevFieldData
      const isNullForNotNull = rootData === null && field.nullable && value === null

      if (!isChanged && !isKey && !isNullForNotNull) continue

      const nullableValue = isNullForNotNull ? '' : value

      if (inputParams) inputParams[field.name] = nullableValue
      else inputParams = { [field.name]: nullableValue }

      hasInputValues = hasInputValues || isChanged
    }
  }

  const isSameItem = newData && prevData && keys.some((key) => prevData[key] === (newData[key] ?? inputParams[key]))

  if (!hasInputValues && !hasNestedUpdates && isSameItem) {
    return [undefined, rootQueries]
  }

  const action = !hasNestedUpdates && isSameItem ? 'edit' : 'create'

  if (!inputParams) inputParams = {}

  if (parentField.root || parentField.dependent) {
    if (newData) {
      parentField.keys?.forEach((key) => {
        const keyValue = newData[key]
        if (keyValue) inputParams[key] = keyValue
      })
    }

    const select = parentField.keys
      ? [...parentField.keys, '_id'].toMapBy(
          (key) => key,
          () => true
        )
      : { _id: true }

    const args = { input: inputParams }

    if (options.queryAliasIdx !== undefined) {
      if (parentField.dependent) {
        const query = {
          [`q${options.queryAliasIdx}_${options.queryIdx++}`]: { alias: `${action}${parentField.name.capitalize()}`, args, select }
        }
        return [undefined, { ...query, ...rootQueries }]
      } else {
        const query = { [`q${options.queryAliasIdx}`]: { alias: `${action}${parentField.name.capitalize()}`, args, select } }
        return [query, rootQueries]
      }
    } else {
      if (parentField.dependent) {
        const query = { [`q${options.queryIdx++}`]: { alias: `${action}${parentField.name.capitalize()}`, args, select } }
        return [undefined, { ...query, ...rootQueries }]
      } else {
        const query = { [`${action}${parentField.name.capitalize()}`]: { args, select } }
        return [query, rootQueries]
      }
    }
  } else {
    if (isSameItem) {
      parentField.keys?.forEach((key) => {
        inputParams[key] = newData[key]
      })
    }
    const nestedQuery = {
      [`${action}${parentField.name.capitalize()}`]: inputParams
    }

    return [nestedQuery, rootQueries]
  }
}
