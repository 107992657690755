import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'

import { generateGql } from './generate-gql/generateGql'
import { GqlQueryInput } from '../types'


// eslint-disable-next-line no-unused-vars
export type MultipleBulk = { [key in 'create' | 'edit' | 'delete' | 'hardDelete']?: any[] }

const generateBulkGql = (entity: string, bulkItems: MultipleBulk) => {
  const queries: { [key: string]: GqlQueryInput } = {}

  let counter = 0

  Object.entries(bulkItems).forEach(([action, items]) => {
    const mutationName = `${action.camelCase()}${entity.capitalize()}`
    if (action === 'delete' || action === 'hardDelete') {
      items?.forEach((item) => {
        queries[`q${counter++}`] = { args: item, alias: mutationName }
      })
    } else {
      items?.forEach((item) => {
        queries[`q${counter++}`] = { args: { input: item }, alias: mutationName, select: { _id: true } }
      })
    }
  })

  return generateGql(queries, 'mutation')
}

export const useBulkMutation = () => {
  const client = useApolloClient()

  return useCallback(
    (entity: string, mutations: MultipleBulk) => {
      return client.mutate({ mutation: generateBulkGql(entity, mutations) })
    },
    [client]
  )
}

export const useBulkMutationCreate = () => {
  const client = useApolloClient()

  return useCallback(
    (entity: string, items: any[]) => {
      return client.mutate({
        mutation: generateBulkGql(entity, {
          create: items,
          edit: [],
          delete: [],
          hardDelete: []
        })
      })
    },
    [client]
  )
}
