/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react'

import { TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { DatePicker } from '@mui/x-date-pickers'
import Moment from 'react-moment'

import { useTranslation } from '../translations'
import { FormField, GqlComponent } from '../types'
import { callOrGet } from '../utils'

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-grid',
      minWidth: '20ch',
      flexGrow: 1
    }
  })
)

interface FormFieldProps extends FormField<string> {
  date?: boolean
}

export const EditTextDateEpoch: GqlComponent<FormFieldProps, string> = (props) => {
  const classes = useStyles()

  const translate = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const parseDate = props.date ? (value: any) => new Date(value) : (value: any) => new Date(parseInt(value))
  const formatDate = props.date ? (value: Date) => value.toISODate() : (value: Date) => value.getTime()

  const item = props.item || props.rowData

  let srcDate = props.value ? parseDate(props.value) : null
  if (!srcDate) {
    srcDate = parseDate(callOrGet(props.default, item) || '')
  }
  if (!srcDate) srcDate = new Date()

  const onChange = props.onChange ? props.onChange : (_date: string) => {}

  const fontSize: number = typeof props.fontSize === 'number' ? props.fontSize : props.size === 'dense' ? 16 : 14

  return (
    <DatePicker
      label={
        props.isForm && (React.isValidElement(props.label) ? props.label : translate(props.label?.toString() || props.name.snakeCase()))
      }
      value={srcDate}
      disabled={callOrGet(props.disabled, srcDate, item)}
      onChange={(date: Date | null) => {
        if (!date) return
        return onChange(formatDate(date).toString())
      }}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      format="dd-MM-yyyy"
      slotProps={{
        openPickerIcon: {
          style: { fontSize: fontSize ? fontSize * 1.5 : undefined }
        }
      }}
      slots={{
        textField: (inputProps: any) => (
          <TextField
            {...inputProps}
            sx={{ m: props.isForm ? 1 : 0 }}
            className={classes.root}
            style={{ fontSize }}
            onSubmit={props.onSubmit}
            variant={props.isForm ? 'outlined' : 'standard'}
            fullWidth={props.fullWidth}
            inputProps={{ ...inputProps.inputProps, style: { fontSize } }}
            error={callOrGet(props.error, srcDate)}
            size={props.size !== 'dense' ? 'medium' : 'small'}
            onFocus={() => setIsOpen(true)}
            onBlur={() => setIsOpen(false)}
          />
        )
      }}
    />
  )
}

EditTextDateEpoch.gql = 'Long'

EditTextDateEpoch.render = (props: FormFieldProps, value: string) => {
  if (!value || value === '0000-00-00') return ''
  const parseDate = props.date ? (value: any) => new Date(value) : (value: any) => new Date(parseInt(value))
  // @ts-ignore
  return <Moment format="D MMM YYYY">{parseDate(value)}</Moment>
}
