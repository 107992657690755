import React from 'react'

import { EditText } from './EditText'
import { NestedItemForm } from './NestedItemForm'
import { useZeroApiContext } from '../context/ZeroApiContext'
import { useTranslation } from '../translations'
import { FormField, GqlComponent } from '../types'

interface FormFieldProps extends FormField<string> {
  language?: string
}

export const TranslationField: GqlComponent<FormFieldProps> = (props) => {
  const { lang } = useZeroApiContext()
  const translate = useTranslation()

  const language = props.language ?? lang

  const labelVal = React.isValidElement(props.label) ? props.label : translate(props.label?.toString() || 'name')

  return (
    <NestedItemForm {...props} name={name} gql="Translation">
      <EditText
        name={language.toLocaleLowerCase()}
        label={props.labelLang ? `${labelVal} (${language})` : labelVal}
        gql="String"
        default=""
        fullWidth
        readOnly={props.readOnly}
      />
    </NestedItemForm>
  )
}
