import React, { useEffect } from 'react'

import { useEntityGql } from '../gql-fields'
import { FormQueryProps, GqlComponent, GqlTabletField } from '../types'
import { callOrGet, getNestedValue, setNestedValue } from '../utils'

interface FormFieldProps extends GqlTabletField<any> {
  isName?: boolean
  hidden?: boolean
  relField?: string
  updateTemplateData?: (tempalteItems: any[]) => void
}

export const TemplateEntity: GqlComponent<any, any> = (props: FormFieldProps & FormQueryProps) => {
  const { fields, query } = useEntityGql({
    ...props,
    item: undefined,
    entityRelFieldName: props.relField,
    entityRelFieldValue: callOrGet(props.value, props.item),
    entity: props.name,
    list: true
  })

  const updateTemplateData = props.updateTemplateData

  useEffect(() => {
    if (query.items && updateTemplateData) {
      const sourceFields = fields.filter((it) => !it.virtual)

      updateTemplateData(
        query.items.map((item) => {
          const templateItem = { _isTemplate: true }
          sourceFields.forEach((field) => {
            if (field.derived) {
              setNestedValue(templateItem, field.targetField ?? field.name, callOrGet(field.value, item))
            } else {
              setNestedValue(templateItem, field.targetField ?? field.name, getNestedValue(item, field.name))
            }
          })
          return templateItem
        })
      )
    }
  }, [fields, query.items, updateTemplateData])

  return null
}

TemplateEntity.skipGql = true

TemplateEntity.render = (props: any, value: any, row: any) => <TemplateEntity {...props} value={value} rowData={row} />
