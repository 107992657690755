import React, { useCallback, useContext, useMemo } from 'react'

import { useSimeReactiveState } from '../../../utils'
import { Selection } from '../types'

export interface ContextType {
  state?: Selection
  updateState: (state: Selection, reRender?: boolean) => void
}

interface Props {
  children: React.ReactElement
  context: React.Context<ContextType>
}

export const FeatureStateProvider: React.FC<Props> = ({ children, context }) => {
  const [state, setState] = useSimeReactiveState<Record<string, string[]>>()

  const updateState = useCallback(
    (state: Record<string, string[]>, reRender = true) => {
      setState((prevState) => {
        if (prevState == null) {
          return state
        } else {
          return { ...prevState, ...state }
        }
      }, reRender)
    },
    [setState]
  )

  const value = useMemo(() => ({ state, updateState }), [state, updateState])

  return <context.Provider value={value}>{children}</context.Provider>
}

export const useReportFeature: (context: React.Context<ContextType>) => Selection | undefined = (context) => {
  return useContext(context).state
}

export const useReportFeatureState: (context: React.Context<ContextType>) => ContextType = (context) => {
  return useContext(context)
}
