import * as React from 'react'
import { useRef, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { AppBar, IconButton, Slide, Toolbar, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { Theme } from '@mui/material/styles'
import { TransitionProps } from '@mui/material/transitions'
import { makeStyles } from '@mui/styles'
import { Breakpoint } from '@mui/system/createTheme'

import { useWindowSize } from './useWindowSize'
import { printDiv } from './Utils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    padding: theme.spacing(1)
  },
  appBar: {
    position: 'relative',
    minWidth: 320
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  closeIcon: {
    width: theme.spacing(5),
    height: 35,
    position: 'absolute',
    zIndex: 99999,
    right: 0
  }
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})
let contentId = 1

interface AppDialogProps {
  open: boolean
  onClose: (arg: any | undefined) => void
  inlineOnDesktop?: boolean
  fullWidth?: boolean
  onAction?: () => boolean
  title: string
  children: JSX.Element
  maxWidth?: Breakpoint
  actionIcon?: React.ReactNode
  background?: string
}

export function AppDialog(props: AppDialogProps) {
  const classes = useStyles()

  const size = useWindowSize()
  const id = useState(contentId++)
  const actionRef = useRef(() => printDiv(`dialog_content_${id}`, 'no_print', true))

  const handleClose = () => {
    props.onClose(undefined)
  }

  if (props.inlineOnDesktop && !size.isCompact) {
    return props.open ? props.children : null
  }

  return (
    <Dialog
      open={props.open}
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      fullScreen={size.isMobile}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={classes.root}
      scroll="body"
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            {props.title || 'Dialog'}
          </Typography>

          {props.actionIcon && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => (props?.onAction && props.onAction()) || actionRef.current()}
              aria-label="close"
            >
              {props.actionIcon}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.content} style={{ backgroundColor: props.background }} id={`dialog_content_${id}`}>
        {props.children}
      </div>
    </Dialog>
  )
}
