import React from 'react'

import { Avatar, Chip } from '@mui/material'
import { deepPurple } from '@mui/material/colors'

import { useTranslation } from '../translations'
import { FormField, GqlComponent } from '../types'
import { callOrGet, ifNull } from '../utils'


interface FormFieldProps extends FormField<any> {}

export const FormChip: GqlComponent<FormFieldProps, any> = (props) => {
  const translate = useTranslation()

  const value = ifNull(props.value, callOrGet(props.default, props.item))

  const size = props.size === 'dense' ? 'small' : 'medium'

  const label = props.label || translate(props.name)

  if (value === 0) {
    return <Chip size={size} color="warning" label={translate('_no_' + label)} variant="outlined" />
  }
  return (
    <Chip
      size={size}
      avatar={
        <Avatar sx={{ bgcolor: deepPurple[100] }}>
          <span style={{ color: deepPurple[600] }}>{value}</span>
        </Avatar>
      }
      label={React.isValidElement(props.label) ? props.label : translate(props.label?.toString() || props.name.snakeCase())}
      sx={{ color: deepPurple[400] }}
      variant="outlined"
    />
  )
}
FormChip.gql = 'String'

FormChip.render = (props: FormFieldProps, value: string) => <FormChip {...props} value={value} />
