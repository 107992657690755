import React, { useCallback, useMemo, useState } from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { SxProps } from '@mui/system'

import { NestedItemForm } from './NestedItemForm'
import { TemplateEntity } from './TemplateEntity'
import { GqlComponent, GqlTabletField } from '../types'
import { isObjectMatching } from '../utils'

interface Props extends GqlTabletField<any, any> {
  dense?: boolean
  isLoading?: boolean
  noDivider?: boolean
  onHasError?: (hasError: boolean) => void
  container?: { component: React.ElementType; props?: React.ComponentPropsWithRef<React.ElementType> }
}

export const GqlFormTable: GqlComponent<Props, string> = (props) => {
  const currentItems = props.value

  const [templateData, setTemplateData] = useState<any[]>([])

  const updateTemplateData = useCallback((templateItems: any[]) => {
    setTemplateData(templateItems.map((it) => structuredClone(it)))
    // setTemplateData((items) => {
    //   return templateItems.map((templateItem, index) => {
    //     return { ...items[index], ...templateItem }
    //   })
    // })
  }, [])

  const items = useMemo<any[]>(() => {
    if (!currentItems?.length || !Array.isArray(currentItems)) return templateData.map((item) => structuredClone(item))

    const templateItems = templateData.filter((templateItem) => currentItems.none((item) => isObjectMatching(templateItem, item)))

    return [...currentItems, ...templateItems.map((item) => structuredClone(item))]
  }, [currentItems, templateData])

  const onChange = useCallback(
    (index: number) => (value: any) => {
      const updatedItems = items ? [...items] : []
      updatedItems[index] = { ...items[index], ...value }
      props.onChange(updatedItems)
    },
    [items, props]
  )

  const childrenArray = useMemo(() => React.Children.toArray(props.children), [props.children])

  const children = useMemo(
    () => childrenArray.filter((child) => React.isValidElement(child) && child.type !== TemplateEntity),
    [childrenArray]
  )
  const helperChildren = useMemo(
    () => childrenArray.filter((child) => React.isValidElement(child) && child.type === TemplateEntity),
    [childrenArray]
  )

  const cellStyle = useMemo<SxProps | undefined>(() => {
    const cellDividerStyle = props.noDivider ? { border: 0 } : undefined
    const cellSizeStyle = props.size === 'dense' ? { paddingLeft: 1.5, paddingTop: 0, paddingBottom: 0 } : undefined
    return cellDividerStyle || cellSizeStyle ? { ...cellSizeStyle, ...cellDividerStyle } : undefined
  }, [props.noDivider, props.size])

  return (
    <TableContainer component={props.container?.component} {...props.container?.props}>
      <Table sx={{ minWidth: 500 }} aria-label="custom dense pagination table" size={props.size === 'dense' ? 'small' : 'medium'}>
        {helperChildren.map(
          (it) => React.isValidElement(it) && React.cloneElement(it, { ...it.props, updateTemplateData }, it.props.children)
        )}
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={`item_${index}`}>
              <NestedItemForm
                {...props}
                key={`item_${index}`}
                name={props.name}
                value={item}
                isLoading={props.isLoading}
                parentRowData={props.item}
                onChange={onChange(index)}
                fieldWrapper={TableCell}
                fieldWrapperProps={cellStyle ? { sx: cellStyle } : undefined}
                onSubmit={props.onSubmit}
                size={props.size !== 'dense' ? 'medium' : 'dense'}
                isForm
              >
                {children}
              </NestedItemForm>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

// GqlFormTable.aggregate = (props) => {
//   const keys = props.keys ?? ['id']

//   return (items) => {
//     items.groupBy(it => )
//   }
// }
