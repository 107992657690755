import React from 'react'

interface ShowProps {
  if: boolean | any | undefined | null
  children?: JSX.Element | JSX.Element[]
  component?: JSX.Element
  fallback?: JSX.Element
}

export default (props: ShowProps): JSX.Element | null => {
  if (!props.if) {
    return props.fallback || null
  }

  if (props.children) {
    return <React.Fragment>{props.children}</React.Fragment>
  } else if (props.component) {
    return props.component
  } else {
    throw Error('Neither compontent nor children is provided to `Show`')
  }
}
