import React from 'react'

import { Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'

import { GqlComponent, GqlTabletField } from '../types'
import { callOrGet, getNestedValue } from '../utils'

interface FormFieldProps extends GqlTabletField<any> {
  variant?: Variant
  gutterBottom?: boolean
}

export const LabelField: GqlComponent<any, any> = (props: FormFieldProps) => {
  const item = props.item || props.rowData || {}

  let value = callOrGet(props.value, item)

  if (props.valueFromParent) {
    if (typeof props.valueFromParent === 'string') {
      value = getNestedValue(props.parentRowData ?? item, props.valueFromParent)
    } else {
      value = props.parentRowData[callOrGet(props.valueFromParent, props.parentRowData)]
    }
  }

  if (props.convert) {
    value = props.convert(value)
  }

  return (
    <Typography variant={props.variant ?? 'body1'} align={props.align} gutterBottom={props.gutterBottom}>
      {value}
    </Typography>
  )
}

LabelField.skipGql = true

LabelField.render = (props: any, value: any, row: any) => <LabelField {...props} value={value} rowData={row} />
