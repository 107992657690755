import { useCallback, useMemo, useState } from 'react'

import { useApolloClient } from '@apollo/client'

import { generateGql } from './generate-gql'
import { generateItemUpdateGql } from './generate-item-update-gql'
import { GqlQuery } from './types'
import { createBulkEditMutations } from '../gql-fields'
import { FieldDeaggregators } from '../gql-fields/types'
import { Args, BulkActionItem, BulkActions, Field, GqlMutation, GqlQueryInput, MutationOptions } from '../types'
import { callOrGet } from '../utils'

const getDeleteItemGql = (entity: string, keys?: string[], alias?: string) => ({
  [`delete${entity}`]: {
    alias,
    args: (keys ?? ['id']).toMapBy(
      (k) => `$${k}`,
      () => 'String!'
    )
  }
})

const getHardDeleteItemGql = (entity: string, keys?: string[], alias?: string) => ({
  [`hardDelete${entity}`]: {
    alias,
    args: (keys ?? ['id']).toMapBy(
      (key) => '$' + key,
      () => 'String!'
    )
  }
})

const getUnDeleteItemGql = (entity: string, keys?: string[], alias?: string) => {
  return {
    [`undelete${entity}`]: {
      alias,
      args: (keys || ['id']).toMapBy(
        (key) => '$' + key,
        () => 'String!'
      )
    }
  }
}

function getBulkSaveItemGql(entity: string, bulkItems: BulkActions, gqlFields: Field<any>[], keys?: string[], counter = 0) {
  const queries: Record<string, GqlQueryInput> = {}

  const unwrapArgs: (container: Field<any>[], item: BulkActionItem) => Args = (container, item) => {
    const items = container.groupBy((it) => (it.dependent ? 'dependent' : 'independent'))
    items.dependent?.forEach((field) => {
      if (!field.childs) throw Error(`field.dependent = true, requires children. Error in ${field.name}`)

      queries[`q${counter++}`] = {
        alias: `create${field.name.capitalize()}`,
        args: {
          input: unwrapArgs(
            Object.values(field.childs)
              .filterNotNull()
              .filter((field) => field.readOnly !== true && field.virtual !== true),
            item[field.name]
          )
        },
        select: (field.keys || ['id', '_id']).toMapBy(
          (key) => key,
          () => true
        )
      }
    })

    return items.independent?.toNotNullMapBy(
      (field) => (field.dependent ? null : field.nested ? field.gqlCreate || '' : field.name),
      (field) => {
        if (!Object.prototype.hasOwnProperty.call(item, field.name)) return null

        const itemFieldValue = item[field.name]
        if (itemFieldValue)
          if (field.nested) {
            if (itemFieldValue == null) return null
            if (!field.childs) throw Error('field.nested = true, requires children')
            const nestedItems = Object.values(field.childs)
              .filterNotNull()
              .filter((field) => field.readOnly !== true && field.virtual !== true && field.list !== true)
            const nestedFields = unwrapArgs(nestedItems, itemFieldValue)
            if (!nestedFields || Object.keys(nestedFields).length === 0) return null
            return nestedFields
          }

        return itemFieldValue
      }
    )
  }

  const saveGqlFields = gqlFields.filter((field) => field.readOnly !== true && field.virtual !== true && field.list !== true)

  Object.entries(bulkItems).forEach(([action, items]) => {
    const mutationName = `${action.camelCase()}${entity.replace(/!$/, '').capitalize()}`

    if (action === 'delete' || action === 'hardDelete') {
      items?.forEach((item) => {
        queries[`q${counter++}`] = { args: item, alias: mutationName }
      })
    } else if (action === 'create' || action === 'edit') {
      items?.forEach((item) => {
        const input = unwrapArgs(saveGqlFields, item)

        queries[`q${counter++}`] = {
          alias: mutationName,
          args: { input },
          select: (keys || ['_id']).toMapBy(
            (key) => key,
            () => true
          )
        }
      })
    }
  })

  return queries
}

export function useEntityMutation(
  entity: string,
  fields: Field<any>[],
  relField: string,
  relValue: any,
  options: MutationOptions = {},
  query?: GqlQuery<any>
) {
  const refetchItems = useCallback(() => {
    if (query) return query.refresh()
    if (options?.onMutate) return options?.onMutate()
    return console.warn('noAutoRefresh & onMutate is not set')
  }, [options, query])

  const [isLoadingAction, setIsLoadingAction] = useState(false)

  const withLoading = <T>(promise: Promise<T>) => {
    setIsLoadingAction(true)
    return promise.finally(() => {
      setIsLoadingAction(false)
    })
  }

  const client = useApolloClient()

  const editableFields = useMemo(() => fields.filter((it) => !it.derived), [fields])

  const allFields = useMemo(() => {
    const keys = options.keys ?? ['id']

    return [...editableFields, ...keys.map((it) => ({ name: it, gql: 'String!' }))]
  }, [editableFields, options.keys])

  const gqlFields = useMemo(() => allFields.filter((field) => field.gql !== undefined).distinctBy((it) => it.name), [allFields])

  const variables = useMemo(() => (options.all ? {} : { [relField]: relValue }), [options.all, relField, relValue])

  const bulkEdit = useCallback(
    (bulkItems: BulkActions, noAutoRefetch?: boolean) => {
      if (!bulkItems.create?.length && !bulkItems.edit?.length && !bulkItems.delete?.length && !bulkItems.hardDelete?.length) {
        return
      }
      const queries = getBulkSaveItemGql(entity, bulkItems, gqlFields, options.keys)
      return withLoading(
        client
          .mutate({
            mutation: generateGql(queries, 'mutation'),
            variables
          })
          .then((e) => {
            if (!noAutoRefetch) return refetchItems()
            return e.data
          })
      )
    },
    [client, entity, gqlFields, options.keys, refetchItems, variables]
  )

  const deepEdit = useCallback(
    (fieldDeaggregators: FieldDeaggregators, prevItem: any | undefined, newItem: any, noAutoRefetch?: boolean) => {
      const rootItem = {}

      let nestedListMutations: Record<string, GqlQueryInput> = {}

      editableFields.forEach((field) => {
        if (!field.aggregated) {
          const value = newItem[field.name]

          if (value === undefined) {
            rootItem[field.name] = prevItem ? prevItem[field.name] : undefined
          } else if (field.gql === 'Long' && value === '') {
            rootItem[field.name] = 'null'
          } else if (field.gql === 'Long!' && value === '') {
            rootItem[field.name] = callOrGet(field.default, { ...prevItem, ...newItem }) ?? 0
          } else {
            rootItem[field.name] = newItem[field.name]
          }
        } else {
          const aggregatedItems = newItem[field.name]

          if (!aggregatedItems.length || !field.childs) return

          const childFields = field.childs != null ? Object.values(field.childs).filterNotNull() : undefined
          if (childFields == null) throw Error('No childs in list field ' + field.name)

          const getBulkEditMutations = createBulkEditMutations(field, fieldDeaggregators)

          aggregatedItems.forEach((aggregatedItem) => {
            const itemMutations = getBulkEditMutations(aggregatedItem)
            if (!itemMutations) return

            const mutations = getBulkSaveItemGql(field.gql ?? field.name, itemMutations, childFields, field.keys)

            if (mutations) {
              nestedListMutations = { ...nestedListMutations, ...mutations }
            }
          })
        }
      })

      const itemUpdates = generateItemUpdateGql(entity, gqlFields, prevItem, rootItem, {
        ...options,
        queryAliasIdx: Object.keys(nestedListMutations).length
      })

      const allQueries = itemUpdates ? { ...itemUpdates, ...nestedListMutations } : nestedListMutations

      return withLoading(
        client
          .mutate({
            mutation: generateGql(allQueries, 'mutation'),
            variables
          })
          .then((e) => {
            if (!noAutoRefetch) return refetchItems()
            return e.data
          })
      )
    },
    [client, entity, editableFields, gqlFields, options, refetchItems, variables]
  )

  const saveItem = useCallback(
    (params: any, noAutoRefetch?: boolean) => {
      const mutations = generateItemUpdateGql(entity, gqlFields, undefined, params, options)
      if (!mutations) return Promise.resolve()

      return withLoading(
        client
          .mutate({
            mutation: generateGql(mutations, 'mutation')
          })
          .then((e) => {
            if (!noAutoRefetch) return refetchItems()
            return e.data[`create${entity}`]
          })
      )
    },
    [client, entity, gqlFields, options, refetchItems]
  )

  const updateItem = useCallback(
    (prevParams: any, newParams: any, noAutoRefetch?: boolean) => {
      const updates = generateItemUpdateGql(entity, gqlFields, prevParams, newParams, options)
      if (!updates) return Promise.resolve()

      return withLoading(
        client.mutate({ mutation: generateGql(updates, 'mutation') }).then((e) => {
          if (!noAutoRefetch) return refetchItems()
          return e.data[`create${entity}`]
        })
      )
    },
    [client, entity, gqlFields, options, refetchItems]
  )

  const deleteItem = useCallback(
    (params: any, noAutoRefetch?: boolean) => {
      return withLoading(
        client
          .mutate({
            mutation: generateGql(getDeleteItemGql(entity, options.keys), 'mutation'),
            variables: params
          })
          .then((e) => {
            if (!noAutoRefetch) return refetchItems()
            return e.data
          })
      )
    },
    [client, entity, options.keys, refetchItems]
  )

  const hardDeleteItem = useCallback(
    (params: any, noAutoRefetch?: boolean) => {
      return withLoading(
        client
          .mutate({
            mutation: generateGql(getHardDeleteItemGql(entity, options.keys), 'mutation'),
            variables: params
          })
          .then((e) => {
            if (!noAutoRefetch) return refetchItems()
            return e.data
          })
      )
    },
    [client, entity, options.keys, refetchItems]
  )

  const unDeleteItem = useCallback(
    (params: any, noAutoRefetch?: boolean) => {
      return withLoading(
        client
          .mutate({
            mutation: generateGql(getUnDeleteItemGql(entity, options.keys), 'mutation'),
            variables: params
          })
          .then((e) => {
            if (!noAutoRefetch) return refetchItems()
            return e.data
          })
      )
    },
    [client, entity, options.keys, refetchItems]
  )

  return {
    isLoadingAction,
    bulkEdit,
    deepEdit,
    saveItem,
    updateItem,
    deleteItem,
    hardDeleteItem,
    unDeleteItem
  } as GqlMutation<any>
}
