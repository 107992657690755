import React from 'react'

import { FormField, GqlComponent } from '../types'
import { callOrGet } from '../utils'

export type ChildProps = FormField<any>

interface FormFieldProps extends FormField<string> {
  glue?: JSX.Element | ((row: any) => JSX.Element | string | null)
}

export const CompositeField: GqlComponent<FormFieldProps, string> = (props: FormFieldProps) => {
  const item = props.item || props.rowData

  const onChange = (field: string) => (value: any) => {
    if (!props.onChange) return

    item[field] = value

    return props.onChange('')
  }

  return (
    <div style={{ display: 'flex' }}>
      {React.Children.map(props.children, (child) => {
        if (!React.isValidElement(child)) return null
        const childType = child?.type
        if (!childType) return null

        if (callOrGet(child.props.hidden, item)) return null

        if (typeof childType === 'string') return React.cloneElement(child, child.props)

        return React.cloneElement(child, {
          ...props,
          ...child?.props,
          rowData: item,
          value: item[child?.props?.name || ''],
          onChange: onChange(child?.props?.name || '')
        })
      })}
    </div>
  )
}

CompositeField.render = (props, _value, row) => {
  const item = row || props.item || props.rowData

  if (typeof props.value === 'function') {
    return props.value(row) ?? ''
  }

  let renderedItems = 0
  return (
    <div style={{ display: 'flex' }}>
      {React.Children.map(props.children, (child) => {
        if (!React.isValidElement(child)) return null
        const childType = child.type
        if (!childType) return null

        const childProps = child.props

        if (callOrGet(childProps.hidden, item)) return null

        if (typeof childType === 'string') return React.cloneElement(child, childProps)

        const render = (childType as GqlComponent<any>)?.render ?? (childType as GqlComponent<any>)?.renderComposite

        const childValue = typeof childProps.value === 'function' ? childProps.value(row) ?? '' : item[childProps.name || '']

        const childComponent = render
          ? render(childProps, childValue, row, 'row')
          : React.cloneElement(child, {
              ...child?.props,
              rowData: item,
              value: childValue
            })

        if (renderedItems++ > 0) {
          const glue = props.glue ? callOrGet(props.glue, row) : () => <React.Fragment>&nbsp;-&nbsp;</React.Fragment>
          if (glue) {
            return (
              <React.Fragment>
                {glue}
                {childComponent}
              </React.Fragment>
            )
          }
        }
        return childComponent
      })}
    </div>
  )
}
