import React from 'react'

import { GqlComponent, GqlTabletField } from '../types'
import { callOrGet, getNestedValue } from '../utils'

interface FormFieldProps extends GqlTabletField<any> {
  isName?: boolean
  hidden?: boolean
}

export const HiddenField: GqlComponent<any, any> = (props: FormFieldProps) => {
  const item = props.item || props.rowData || {}

  let value = callOrGet(props.value, item)

  if (props.valueFromParent) {
    if (typeof props.valueFromParent === 'string') {
      value = getNestedValue(props.parentRowData ?? item, props.valueFromParent)
    } else {
      value = props.parentRowData[callOrGet(props.valueFromParent, props.parentRowData)]
    }
  }

  if (item[props.name] !== value) {
    item[props.name] = value
  }

  return null
}

HiddenField.gql = 'String'

HiddenField.render = (props: any, value: any, row: any) => <HiddenField {...props} value={value} rowData={row} />
