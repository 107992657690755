import { GqlQuery } from './types'
import { useEntityMutation } from './useEntityMutation'
import { useZeroApiContext } from '../context/ZeroApiContext'
import { Field, MutationOptions, QueryOptions } from '../types'


export function useEntityRelationMutation(
  entity: string,
  fields: Field<any>[],
  relField?: string,
  relValue?: string,
  options?: MutationOptions,
  query?: GqlQuery<any>
) {
  const context = useZeroApiContext()
  const field = relField !== undefined ? relField : `${context.name.toLowerCase()}Id`
  const value = relField !== undefined ? relValue : context.id
  return useEntityMutation(entity, fields, field, value, options, query)
}

export function useEntityContextMutation(entity: string, fields: Field<any>[], options?: QueryOptions, query?: GqlQuery<any>) {
  const context = useZeroApiContext()
  const field = `${context.name.toLowerCase()}Id`
  return useEntityMutation(entity, fields, field, context.id, options, query)
}
