import React, { useEffect, useMemo } from 'react'

import { ContextType, useReportFeatureState } from './FeatureStateProvider'
import { useResetableState } from '../../../utils'
import { Selection } from '../types'

export interface StateElement {
  name: string
  selection?: string[]
}

export const useFeature = <T extends StateElement>(context: React.Context<ContextType>, remoteState?: T[]) => {
  const { state, updateState } = useReportFeatureState(context)

  const [initialReportFilters] = useResetableState(remoteState, [state != null || remoteState != null], true)

  const remoteSelection = useMemo(
    () =>
      remoteState?.toMapBy(
        (it) => it.name,
        (it) => it.selection ?? []
      ),
    [remoteState]
  )

  useEffect(() => {
    if (remoteSelection == null) return
    if (state == null) {
      updateState(remoteSelection, false)
    }
  }, [state, updateState, remoteSelection])

  const selection = state ?? remoteSelection

  return useMemo<[T[] | undefined, Selection | undefined, (state: Selection) => void]>(
    () => [initialReportFilters, selection, updateState],
    [initialReportFilters, selection, updateState]
  )
}
