import React, { useMemo } from 'react'

import { FieldAggregators, FieldDeaggregators, FieldValueGetter } from './types'
import { FilterField, GqlDetailPanel, GqlDetailPanelProps, GqlFormTable, HiddenField, NestedItemForm } from '../fields'
import { GqlTableProps } from '../gql-table'
import { FieldMap, GqlComponent, GqlTabletField } from '../types'
import { ifNull, uid } from '../utils'

export const getNestedFieldValue = (item: any, path: string[], fieldName: string) => {
  for (let i = 0; i < path.length; i++) {
    item = item[path[i]] || {}
  }
  return item[fieldName] ?? item
}

export const useProcessFields = (props: GqlTableProps, fieldsMap: FieldMap) => {
  return useMemo(() => {
    const parentRowData = props.rowData
    const getParentValue = (value: string | ((rowData: any) => any) | undefined) => {
      if (parentRowData === undefined || value === undefined) return undefined
      if (value instanceof Function) return value(parentRowData)
      return parentRowData[value]
    }

    const propsTreeLookup = props.treeLookup
    let treeItemsLookup: ((row: any, rows: any[]) => any[]) | undefined = propsTreeLookup
      ? (row: any, rows: any[]) => row && rows && rows.find((a) => a && propsTreeLookup(a, row))
      : undefined

    const fieldValueGetters: FieldValueGetter[] = []

    const fieldAggregators: FieldAggregators = []
    const fieldDeaggregators: FieldDeaggregators = []
    const groupByColumns: string[] = []

    const generateColumns =
      (fieldsMapParent: FieldMap, path: string[], keys?: string[], isParentAggregated?: boolean) =>
      (child: React.ReactElement<GqlTabletField<any> | GqlDetailPanelProps>) => {
        if (!React.isValidElement(child)) {
          console.warn('Invalid child', child)
          return
        }
        const component = child.type as GqlComponent<any>

        if (component === GqlDetailPanel) return

        const childProps = child.props as GqlTabletField<any>
        const fieldName = childProps.name

        if (fieldName === undefined) return

        const aggregated = props.aggregated || isParentAggregated

        if (aggregated && childProps.ignoreOnAggregate) return
        if (!aggregated && childProps.ignoreOnNonAggregate) return

        const field = fieldsMapParent[fieldName]

        if (field === undefined && !component.aggregator) return

        if (component === NestedItemForm || component === GqlFormTable) {
          if (!field) {
            console.warn("NestedItemForm got null 'field'")
            return
          }
          if (!childProps.children) {
            console.warn('NestedItemForm with no children')
            return
          }
          if (!field.childs) {
            console.warn('field.childs unexpetidely is undefined')
            return
          }
          const columnGenerator = generateColumns(
            field.childs as FieldMap,
            component === GqlFormTable ? [] : path.concat([fieldName]),
            field.keys,
            childProps.aggregated || isParentAggregated
          )
          React.Children.forEach(childProps.children, columnGenerator)
          if (component === GqlFormTable) return
          // TODO: create aggregator/disagregator
          // if (!aggregated) return

          // return
          // }
        }

        const getValue =
          childProps.valueFromParent !== undefined
            ? (_row: any) => getParentValue(childProps.valueFromParent)
            : path.length === 0
            ? (row: any) => ifNull(row[fieldName], field ? field.value : row)
            : (row: any) => {
                let value = row
                for (let i = 0; i < path.length; i++) {
                  value = value[path[i]] || {}
                }
                return ifNull(value[fieldName], field ? field.value : row)
              }

        const fieldPathName = path.length > 0 ? path.concat(childProps.name).join('.') : childProps.name

        fieldValueGetters.push({
          fieldPathName,
          getValue,
          default: childProps.default,
          isValueFromParent: childProps.valueFromParent !== undefined
        })

        if (childProps.treeLookup) {
          const otherField = fieldValueGetters.find((it) => it.fieldPathName === childProps.treeLookup)
          if (otherField) {
            const otherGetValue = otherField.getValue
            treeItemsLookup = (row, rows) => {
              if (!row || !rows) return undefined
              const rowValue = getValue(row)
              return rows.find((a) => otherGetValue(a) === rowValue)
            }
          } else {
            console.error(`Can not find field ${childProps.treeLookup} in ${fieldValueGetters.map((it) => it.fieldPathName)}`)
          }
        }

        if (aggregated && !(component === HiddenField || component === FilterField)) {
          if (childProps.aggregate) {
            fieldAggregators.push([fieldPathName, childProps.aggregate])
          } else if (component.aggregate) {
            fieldAggregators.push([fieldPathName, component.aggregate(childProps)])
          } else {
            groupByColumns.push(fieldPathName)
          }
        }

        if (aggregated) {
          const isKey = keys ? keys?.includes(fieldName) : fieldName === 'id'
          if (childProps.disaggregate) {
            fieldDeaggregators.push([fieldPathName, childProps.disaggregate])
          } else if (childProps.disaggregateCreateNull) {
            fieldDeaggregators.push([
              fieldPathName,
              (item) => {
                const countRefValue = childProps.disaggregateCreateNull
                if (!countRefValue) return []
                const count = parseInt(typeof countRefValue === 'string' ? item[countRefValue] : countRefValue)
                const values = (item._agrItems ?? []).map((it: any) => it[fieldName])
                if (values.length === count) return values
                if (values.length > count) return values.slice(0, count)
                return values.concat(Array(count - values.length).fill(null))
              }
            ])
          } else if (childProps.disaggregateCreateId || isKey) {
            const deaggregator = (item: any, resultCount?: number) => {
              const countRefValue = childProps.disaggregateCreateId
              const count = countRefValue
                ? parseInt(typeof countRefValue === 'string' ? getNestedFieldValue(item, path, countRefValue) : countRefValue)
                : resultCount ?? item._agrItems?.length

              if (!count || !Number.isSafeInteger(count)) return []

              const values = (item._agrItems ?? []).map((it: any) => getNestedFieldValue(it, path, fieldName))

              if (values.length === count) return values
              if (values.length > count) return values.slice(0, count)

              const newItemValues = Array(count - values.length)
              for (let index = 0; index < newItemValues.length; index++) {
                newItemValues[index] = uid()
              }
              return values.concat(newItemValues)
            }

            if (isKey) {
              fieldDeaggregators.unshift([fieldPathName, deaggregator])
            } else {
              fieldDeaggregators.push([fieldPathName, deaggregator])
            }
          }
        }
      }

    React.Children.forEach(props.children, generateColumns(fieldsMap, [], props.keys))

    return { fieldDeaggregators, fieldValueGetters, groupByColumns, fieldAggregators, treeItemsLookup }
  }, [fieldsMap, props.aggregated, props.children, props.keys, props.rowData, props.treeLookup])
}
