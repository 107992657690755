import React, { useCallback, useState } from 'react'

import { EditText } from './EditText'
import { GqlTabletField } from '../types'
import { callOrGet } from '../utils'

type FormFieldProps = GqlTabletField<number> & {
  minValue?: number | ((item: any) => number)
  maxValue?: number | ((item: any) => number)
  hint?: string
  type?: React.InputHTMLAttributes<unknown>['type']
}

export const EditTextInt = (props: FormFieldProps) => {
  const [error, setError] = useState(false)

  const minValue = callOrGet(props.minValue, props.item ?? props.rowData)
  const maxValue = callOrGet(props.maxValue, props.item ?? props.rowData)
  const { nullable, onChange } = props

  const onChangeValue = useCallback(
    (value: any | null) => {
      if (value == null && !nullable) {
        setError(true)
        return true
      } else if (isNaN(value)) {
        setError(true)
        return true
      } else if (minValue != null && value < minValue) {
        setError(true)
        return true
      } else if (maxValue != null && value > maxValue) {
        setError(true)
        return true
      }
      if (error) {
        setError(false)
      }
      return onChange?.(value)
    },
    [error, nullable, maxValue, minValue, onChange]
  )

  return <EditText {...props} error={error} onChange={onChangeValue} intNumber />
}

EditTextInt.gql = 'Long'

EditTextInt.align = 'center'
EditTextInt.noPadding = true

// EditTextInt.cellStyle = { width: '10%'}

EditTextInt.validate = (props: FormFieldProps, value: number) => {
  if (props.minValue !== undefined && value < callOrGet(props.minValue, props.item ?? props.rowData)) return true
  return props.maxValue !== undefined && value > callOrGet(props.maxValue, props.item ?? props.rowData)
}
