import React from 'react'

import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'

import { useTranslation } from '../translations'
import { FormField, GqlComponent } from '../types'
import { callOrGet, ifNull } from '../utils'

interface FormFieldProps extends FormField<number> {
  textual?: boolean
}

export const FormSwitch: GqlComponent<FormFieldProps, number> = (props) => {
  const translate = useTranslation()
  const item = props.item ?? props.rowData
  const value = ifNull(props.value, callOrGet(props.default, item))

  const control = (
    <Switch
      id={`form_${props.name}`}
      name={props.name}
      disabled={callOrGet(props.disabled, value, item)}
      style={{ fontSize: props.fontSize ?? props.size === 'dense' ? 12 : 14 }}
      checked={value}
      required
      onChange={(e) => {
        if (!props.onChange) return
        return props.onChange(e.target.checked ? 1 : 0)
      }}
      color="primary"
      inputProps={{ 'aria-label': 'primary checkbox' }}
      autoFocus
    />
  )
  if (!props.isForm) return control

  return (
    <FormGroup aria-label="position" row>
      <FormControlLabel control={control} label={props.label ?? translate(props.name.snakeCase())} labelPlacement="start" />
    </FormGroup>
  )
}

const Render = ({ value }: { value: number }) => {
  const translate = useTranslation()
  return <div>{translate(value ? 'yes' : 'no')}</div>
}

FormSwitch.render = (props: FormFieldProps, value: number) => {
  if (props.textual) return <Render value={value} />
  return value ? <Check fontSize="small" color="success" sx={{ p: 0 }} /> : <Close color="action" fontSize="small" sx={{ p: 0 }} />
}

FormSwitch.convert = (value: any) => {
  if (value === true) return 1
  if (value === false || value === undefined || value === null) return 0
  if (value === 'true') return 1
  if (value === 'false') return 0
  return parseInt(value)
}

FormSwitch.cellStyle = {
  padding: 0,
  textAlign: 'center'
}

FormSwitch.shrinkWidth = true
FormSwitch.align = 'center'
FormSwitch.gql = 'Long'
