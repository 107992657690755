import { FieldDeaggregators } from './types'
import { MultipleBulk } from '../gql'
import { Field } from '../types'
import { NO_DIFF, getNestedValue, getObjectChanges, mergeDeep, setNestedValue } from '../utils'

export const createBulkEditMutations = (field: Field<any>, fieldDeaggregators: FieldDeaggregators) => {
  const keys = field.keys

  return (item: any) => {
    if (!fieldDeaggregators.length) throw Error('Bulk edit without disaggreate column. ' + fieldDeaggregators)

    const itemUpdates = getObjectChanges(item._agrItems?.[0], item)
    keys?.forEach((key) => {
      if (!itemUpdates[key]) {
        itemUpdates[key] = item[key]
      }
    })

    let result: any[] | undefined
    fieldDeaggregators.forEach(([column, disaggregate]) => {
      if (result == null) {
        result = disaggregate(item).map((value, index) =>
          setNestedValue(
            {
              ...(item._agrItems?.[index] ?? item),
              ...itemUpdates,
              _id: undefined
            },
            column,
            value
          )
        )
      } else {
        disaggregate(item, result.length).forEach((value, index) => {
          if (result == null) return
          const itemData = result[index]
          if (!itemData) {
            if (!(field.childs?.[column] as any)?.aggregate) return
            const newItemData = {}
            setNestedValue(newItemData, column, value)
            result[index] = newItemData
          } else {
            setNestedValue(itemData, column, value)
          }
        })
      }
    })

    const prevData = item._agrItems ?? []
    const mutations: MultipleBulk = {}

    prevData.forEach((prevItem: any) => {
      const newItem = keys
        ? result?.find((it) => keys?.all((key) => getNestedValue(it, key) === getNestedValue(prevItem, key)))
        : result?.find((it) => it.id === prevItem.id)

      if (newItem === undefined) {
        const args = keys?.toMapBy(
          (it) => it,
          (it) => prevItem[it]
        ) ?? { id: prevItem.id }

        if (mutations.delete) mutations.delete.push(args)
        else mutations.delete = [args]
        return
      }
      const itemUpdates = getObjectChanges(prevItem, newItem, (key) => key.charAt(0) === '_')

      if (itemUpdates !== NO_DIFF) {
        // const gqlFields = Object.values(field.childs ?? {}).filterNotNull()
        // const options = { keys }
        // const updates = generateItemUpdateGql(field.gql ?? field.name, gqlFields, prevItem, newItem, options)

        const args = keys?.toMapBy(
          (it) => it,
          (it) => prevItem[it]
        ) ?? { id: prevItem.id }

        const createItem = mergeDeep(itemUpdates, args)

        Object.entries(field.childs ?? {}).forEach(([key, child]) => {
          if (child?.nullable || child?.nested) return
          if (createItem[key] != null) return
          createItem[key] = prevItem[key]
        })

        if (mutations.create) mutations.create.push(createItem)
        else mutations.create = [createItem]
      }
    })

    result?.forEach((newItem: any) => {
      const prevItem = newItem
        ? keys
          ? prevData?.find((it: any) => keys?.all((key) => getNestedValue(it, key) === getNestedValue(newItem, key)))
          : prevData?.find((it: any) => it.id === newItem.id)
        : undefined

      if (prevItem !== undefined) {
        return
      }

      const createItem = mergeDeep({ ...item }, newItem)

      if (mutations.create) mutations.create.push(createItem)
      else mutations.create = [createItem]
    })

    mutations.create?.cleanUpObjects((key) => key.charAt(0) === '_')
    mutations.edit?.cleanUpObjects((key) => key.charAt(0) === '_')
    mutations.delete?.cleanUpObjects((key) => key.charAt(0) === '_')

    if (!mutations.create && !mutations.edit && !mutations.delete) return null

    return mutations
  }
}
