import React, { useMemo } from 'react'

import { GqlReportProps } from './GqlReport'
import { ReportFilter, Selection } from './types'
import { CompositeField, FilterField } from '../../fields'
import { GqlComponent, GqlTabletField } from '../../types'
import { callOrGet } from '../../utils'

export function useReportFieldsFilter(props: GqlReportProps): Selection {
  const aggregated = props.aggregated ?? false

  return useMemo(() => {
    const filters: Selection = {}

    const loadFieldMap = (childElement?: JSX.Element | React.ReactElement<GqlTabletField<any>, GqlComponent<GqlTabletField<any>>>) => {
      if (!childElement || childElement.type === 'div') return

      const child = childElement as React.ReactElement<GqlTabletField<any>, GqlComponent<GqlTabletField<any>>>
      const childProps = child.props

      if (aggregated && childProps.ignoreOnAggregate) return
      if (!aggregated && childProps.ignoreOnNonAggregate) return

      if (child.type === CompositeField) {
        React.Children.forEach(child.props.children, loadFieldMap)
        return
      }

      const gqlType = childProps.gql !== undefined ? childProps.gql : child.type.gql
      // eslint-disable-next-line no-console
      if (!gqlType) console.warn('gql-type not defined in ', child.type.name)

      if (child.type === FilterField) {
        const value =
          childProps.valueFromParent && props.getParentValue
            ? callOrGet(props.getParentValue, childProps.valueFromParent)
            : childProps.value

        const selection = filters[childProps.name]
        if (selection != null) {
          selection.push(value)
        } else {
          filters[childProps.name] = [value]
        }
      }
    }

    React.Children.forEach(props.children, loadFieldMap)

    return filters
  }, [aggregated, props.children, props.getParentValue])
}
