import { useCallback } from 'react'

import { MultipleBulk } from '../gql'
import { FormQueryProps } from '../types'

export const useCreateBulkDeleteMutations = (props: FormQueryProps, disaggregateColumns: Record<string, (item: any) => any[]>) => {
  return useCallback(
    (item: any) => {
      if (!disaggregateColumns.length) throw Error('Bulk edit without disaggreate column. ' + props.entity)

      const deleteMutation = item._agrItems?.map(
        (item: any) =>
          props.keys?.toMapBy(
            (it) => it,
            (it) => item[it]
          ) ?? { id: item.id }
      )

      if (!deleteMutation.length) return

      const mutations: MultipleBulk = {
        delete: deleteMutation
      }

      mutations.delete?.cleanUpObjects((key) => key.charAt(0) === '_')

      return mutations
    },
    [disaggregateColumns.length, props.entity, props.keys]
  )
}
