import React from 'react'

import { CircularProgress, LinearProgress } from '@mui/material'

import { GqlFormProgressBarProps } from './types'

export const GqlFormProgressBar: React.FC<GqlFormProgressBarProps> = (props) => {
  if (props.loadingBar !== undefined) return props.loadingBar
  if (props.loadingVariant === 'circular') return <CircularProgress />
  return <LinearProgress variant="indeterminate" style={{ width: 220 }} />
}
