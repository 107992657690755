import React, { useEffect, useState } from 'react'

import PersonIcon from '@mui/icons-material/Person'
import { CircularProgress, Tooltip } from '@mui/material'
import moment from 'moment'

import { HiddenField } from './HiddenField'
import { useEntityQuery } from '../gql'
import { useTranslation } from '../translations'
import { FormField, GqlComponent, GqlSelection } from '../types'
import { callOrGet, ifNull } from '../utils'

export interface ItemCreators {
  createdBy?: { profile: { name: string } }
  updatedBy?: { profile: { name: string } }
  created: string
  updated: string
}

interface FormFieldProps extends FormField<any> {
  userId?: string
  rowData?: any
  param?: any
}

export const FormCreators: GqlComponent<FormFieldProps, any> = (props: FormFieldProps) => {
  const translate = useTranslation()

  const [isOpen, setIsOpen] = useState(false)

  const value = ifNull(props.value, callOrGet(props.default, props.item))

  const itemData = props.rowData || props.item || {}

  const fields = [
    { name: 'createdBy', gql: 'User', subSelection: { profile: { name: true } } as GqlSelection },
    { name: 'updatedBy', gql: 'User', subSelection: { profile: { name: true } } as GqlSelection },
    { name: 'created', gql: 'String' },
    { name: 'updated', gql: 'String' }
  ]

  const query = useEntityQuery<ItemCreators>(props.parentEntity || '', fields, 'id', value, {
    single: true,
    skip: !isOpen
  })

  useEffect(() => {
    if (isOpen) {
      query.refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, JSON.stringify(itemData)])

  const item = query.item || ({} as ItemCreators)

  const titleItems: (string | JSX.Element)[] = []
  if (item.createdBy?.profile) {
    titleItems.push(translate('created_by') + " '" + item.createdBy.profile.name + "'")
    if (item.created) titleItems.push(` ${translate('on')} ` + moment(parseInt(item.created)).format('lll'))
    titleItems.push(<br />)
  }
  if (item.updatedBy?.profile) {
    titleItems.push(translate('updated_by') + " '" + item.updatedBy.profile.name + "'")
    if (item.created) titleItems.push(` ${translate('on')} ` + moment(parseInt(item.updated)).format('lll'))
    titleItems.push(<br />)
  }
  if (titleItems.length === 0) {
    titleItems.push(translate('no_info'))
  }

  const title = !isOpen ? '' : query.isLoading ? <CircularProgress size={24} /> : titleItems

  return (
    <Tooltip style={{ cursor: 'help' }} title={title} onOpen={() => setIsOpen(true)} onClick={() => setIsOpen(false)}>
      <PersonIcon sx={{ fontSize: props.fontSize ? props.fontSize * 1.5 : undefined }} color="info" />
    </Tooltip>
  )
}
FormCreators.readOnly = true
FormCreators.skipGql = true
FormCreators.fields = (props) => {
  return [
    <HiddenField key="createdById" name="createdById" value={props.userId?.toString()} nullable />,
    <HiddenField key="created" name="created" value={Date.now().toString()} nullable />,
    <HiddenField key="updatedById" name="updatedById" forceValue={props.userId?.toString()} />,
    <HiddenField key="updated" name="updated" forceValue={Date.now().toString()} />
  ]
}

FormCreators.render = (props: FormFieldProps, value: string, row: any) => <FormCreators {...props} rowData={row} value={value} />
